/* eslint-disable */
import React, { ReactElement, useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import InputMask from 'react-input-mask';

import {
  RiArrowUpSLine,
  RiArrowRightSLine,
  RiDossierLine,
  RiUserLine,
  RiArrowLeftSLine,
  RiFireLine,
  RiOpenArmLine,
  // RiHeartPulseLine,
  RiCapsuleLine,
  RiBattery2ChargeLine,
  RiFileCopyLine,
  RiWaterFlashFill,
  RiWaterFlashLine,
  RiZzzFill,
} from 'react-icons/ri';

import Button from '../Button';
import Toast from '../Toast';

import PregnantWalletBar from '../../assets/PregnantWalletBar.svg';

import customerService from '../../services/customer';
import childrenService from '../../services/children';

import {
  Container,
  WalletModal,
  WalletModalContent,
  WalletModalTexts,
  WalletMenu,
  WalletMenuItem,
  WalletMenuItemIcon,
  WalletMenuItemTexts,
  MyDataModal,
  MyDataModalContent,
  MyDataForm,
  InputControl,
  ChallengesHistoryModal,
  HistoryList,
  HistoryItem,
  HistoryItemIcon,
  ChallengesHistoryModalContent,
  BabyDataModal,
  BabyDataModalContent,
  BabyDataForm,
  CustomInput,
} from './styles';

const PregnantWallet = (): ReactElement => {
  const [isVisibleModal, setIsVisibleModal] = useState<boolean>(false);
  const [isVisibleForm, setIsVisibleForm] = useState<boolean>(false);
  const [isVisibleBabyModal, setIsVisibleBabyModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formInputs, setFormInputs] = useState<any>({});
  const [meInformations, setMeInformations] = useState<any>([]);
  const [isVisibleHistoryModal, setIsVisibleHistoryModal] =
    useState<boolean>(false);
  const [challengeHistory, setChallengeHistory] = useState<any>([]);
  const [myData, setMyData] = useState<any>([]);
  const [customerId, setCustomerId] = useState<number | any>(null);
  const [babyData, setBabyData] = useState<any>([]);
  const [childrenInformations, setChildrenInformations] = useState<any>([]);
  const [isVisibleToast, setIsVisibleToast] = useState<boolean>(false);
  const [CPF, setCPF] = useState<boolean>(false);
  const [networkCode, setNetworkCode] = useState<string | number | null>(null);

  useEffect(() => {
    getMeInformations();
  }, []);

  useEffect(() => {
    getMeInformations();
  }, [isVisibleModal]);

  async function handleSubmitForm(event: any): Promise<void> {
    event.preventDefault();
    setIsLoading(true);

    try {
      setTimeout(async () => {
        await customerService.update(customerId, formInputs);

        setIsLoading(false);
      }, 2000);
    } catch {
      console.log('Error when saves a pregnant data.');
    }
  }

  async function handleChildrenUpdate(event: any): Promise<void> {
    event.preventDefault();
    setIsLoading(true);

    try {
      if (networkCode) {
        await childrenService.attach(networkCode);
      }

      if (Object.keys(formInputs).length === 0 && !networkCode) {
        await childrenService.update(childrenInformations?.id, formInputs)
      }

    } catch {
      console.log('Error when saves a children data.');
    }

    setIsLoading(false);
  }

  function handleInputChange(event: any): void {
    const { name, value } = event?.target;

    setFormInputs({
      ...formInputs,
      [name]: value,
    });
  }

  async function getMeInformations(): Promise<void> {
    try {
      const customerResponse = await customerService.getMe();
      setCustomerId(customerResponse?.customer?.id);

      localStorage.setItem(
        '@App:customerId',
        JSON.stringify(customerResponse?.customer?.id),
      );

      getBabyData(customerResponse?.customer?.id);
      getChallengesHistory(customerResponse?.customer?.id);

      // const myDataInformations = (
      //   await customerService.getOne(customerResponse?.customer?.id)
      // )?.data?.data?.attributes;

      // setMyData(myDataInformations);
      setMeInformations(customerResponse);

      const [childrenInfos] = customerResponse?.customer?.children;
      setChildrenInformations(childrenInfos);
      return;
    } catch {
      return;
    }
  }

  async function getChallengesHistory(id: number): Promise<void> {
    try {
      const challengesHistory = (await customerService.getChallenges(id))?.data
        ?.attributes?.challenges?.data;

      console.log(challengesHistory);
      setChallengeHistory(challengesHistory);
      return;
    } catch {
      console.log('Error when get me informations.');
      return;
    }
  }

  async function getBabyData(id: number): Promise<void> {
    try {
      const [babyDataResponse] = (await customerService.getChildren(id))?.data
        ?.attributes?.children?.data;

      setNetworkCode(babyDataResponse?.attributes?.code || null);
      setBabyData(babyDataResponse);
      return;
    } catch {
      console.log('Error when get me informations.');
      return;
    }
  }

  function handleCopyClipboard(toCopy: string): any {
    setIsVisibleToast(true);
    navigator.clipboard.writeText(toCopy);

    setTimeout(() => {
      setIsVisibleToast(false);
    }, 2500);
  }

  const getExerciseIcon = {
    'measurements': <RiBattery2ChargeLine />,
    'meditation': <RiFireLine />,
    'exercise': <RiFireLine />,
    'water': <RiWaterFlashLine />,
    'sleep': <RiZzzFill />,
  } as any;

  return (
    <>
      <Container onClick={() => setIsVisibleModal(true)}>
        <img src={PregnantWalletBar} alt="PregnantWalletBar" />
      </Container>

      <WalletModal isVisible={isVisibleModal}>
        <WalletModalContent>
          <WalletModalTexts>
            <h2>Minha carteirinha</h2>
            <p>Carteira da gestante e do bebê</p>
          </WalletModalTexts>

          <WalletMenu>
            <WalletMenuItem onClick={() => setIsVisibleForm(true)}>
              <WalletMenuItemIcon>
                <RiUserLine />
              </WalletMenuItemIcon>
              <WalletMenuItemTexts>
                <h4>Meus dados</h4>
                <small>Informações pessoais</small>
              </WalletMenuItemTexts>
              <RiArrowRightSLine />
            </WalletMenuItem>

            <WalletMenuItem
              variant="secondary"
              onClick={() => setIsVisibleBabyModal(true)}
            >
              <WalletMenuItemIcon>
                <RiOpenArmLine />
              </WalletMenuItemIcon>
              <WalletMenuItemTexts>
                <h4>Dados do bebê</h4>
                <small>Informações do seu bebê</small>
              </WalletMenuItemTexts>
              <RiArrowRightSLine />
            </WalletMenuItem>

            <WalletMenuItem
              onClick={() => setIsVisibleHistoryModal(true)}
              variant="tertiary"
            >
              <WalletMenuItemIcon>
                <RiFireLine />
              </WalletMenuItemIcon>
              <WalletMenuItemTexts>
                <h4>Histórico de desafios</h4>
                <small>Veja o seu histórico</small>
              </WalletMenuItemTexts>
              <RiArrowRightSLine />
            </WalletMenuItem>

            <WalletMenuItem disabled variant="quartenary">
              <WalletMenuItemIcon>
                <RiDossierLine />
              </WalletMenuItemIcon>
              <WalletMenuItemTexts>
                <h4>Consultas</h4>
                <small>Registro das minhas consultas</small>
              </WalletMenuItemTexts>
              <RiArrowRightSLine />
            </WalletMenuItem>

            <WalletMenuItem disabled variant="quinary">
              <WalletMenuItemIcon>
                <RiCapsuleLine />
              </WalletMenuItemIcon>
              <WalletMenuItemTexts>
                <h4>Medicamentos</h4>
                <small>Lembretes, doses e nomenclaturas</small>
              </WalletMenuItemTexts>
              <RiArrowRightSLine />
            </WalletMenuItem>

            {/* <WalletMenuItem disabled>
              <WalletMenuItemIcon>
                <RiHeartPulseLine />
              </WalletMenuItemIcon>
              <WalletMenuItemTexts>
                <h4>Exames laboratoriais</h4>
                <small>Anexo dos meus exames</small>
              </WalletMenuItemTexts>
              <RiArrowRightSLine />
            </WalletMenuItem> */}

            {/* <WalletMenuItem disabled>
              <WalletMenuItemIcon>
                <RiDossierLine />
              </WalletMenuItemIcon>
              <WalletMenuItemTexts>
                <h4>Ultrassonografia</h4>
                <small>Registros dos profissionais</small>
              </WalletMenuItemTexts>
              <RiArrowRightSLine />
            </WalletMenuItem>

            <WalletMenuItem disabled>
              <WalletMenuItemIcon>
                <RiDossierLine />
              </WalletMenuItemIcon>
              <WalletMenuItemTexts>
                <h4>Cirurgias prévias</h4>
                <small>Informações de cirurgias realizadas</small>
              </WalletMenuItemTexts>
              <RiArrowRightSLine />
            </WalletMenuItem>

            <WalletMenuItem disabled>
              <WalletMenuItemIcon>
                <RiDossierLine />
              </WalletMenuItemIcon>
              <WalletMenuItemTexts>
                <h4>Vacinas</h4>
                <small>Carteira de vacinação digital</small>
              </WalletMenuItemTexts>
              <RiArrowRightSLine />
            </WalletMenuItem> */}
          </WalletMenu>

          <button type="button" onClick={() => setIsVisibleModal(false)}>
            <RiArrowUpSLine />
            Voltar
          </button>
        </WalletModalContent>
      </WalletModal>

      <MyDataModal isVisible={isVisibleForm}>
        <MyDataModalContent>
          <span onClick={() => setIsVisibleForm(false)}>
            <RiArrowLeftSLine />
            Voltar
          </span>

          <h3>Meus dados</h3>
          <small>Informações pessoais</small>

          <MyDataForm onSubmit={e => handleSubmitForm(e)}>
            <InputControl>
              <small>Nome completo</small>
              <input
                type="text"
                placeholder="Nome completo"
                onChange={e => handleInputChange(e)}
                name="name"
                defaultValue={meInformations?.customer?.name || ''}
              />
            </InputControl>

            <InputControl>
              <small>CPF</small>
              {meInformations?.customer?.cpf && !CPF ? (
                <input
                  type="text"
                  placeholder="CPF"
                  onChange={e => handleInputChange(e)}
                  name="cpf"
                  defaultValue={meInformations?.customer?.cpf || ''}
                  onClick={() => setCPF(true)}
                />
              ) : (
                <InputMask
                  mask="999.999.999-99"
                  placeholder="Digite o seu CPF"
                  className="cpfInputMask"
                  required
                  name="cpf"
                  onChange={e => {
                    handleInputChange(e);
                  }}
                />
              )}
            </InputControl>

            <InputControl>
              <small>E-mail</small>
              <input
                type="email"
                placeholder="E-mail"
                onChange={e => handleInputChange(e)}
                name="email"
                defaultValue={meInformations?.email || ''}
              />
            </InputControl>
            <InputControl>
              <small>Celular</small>
              <input
                type="phone"
                placeholder="Celular"
                onChange={e => handleInputChange(e)}
                name="phone"
                defaultValue={meInformations?.customer?.phone || ''}
              />
            </InputControl>

            <InputControl>
              <small>Sua data de nascimento</small>
              <input
                type="date"
                placeholder="Sua data de nascimento"
                onChange={e => handleInputChange(e)}
                name="birthDate"
                defaultValue={meInformations?.customer?.birthDate || ''}
              />
            </InputControl>

            <InputControl>
              <small>Última menstruação</small>
              <input
                type="date"
                placeholder="Última menstruação"
                onChange={e => handleInputChange(e)}
                name="lastPeriod"
                defaultValue={childrenInformations?.lastPeriod || ''}
              />
            </InputControl>

            <InputControl>
              <small>Grupo sanguíneo</small>
              <input
                type="text"
                placeholder="Grupo sanguíneo"
                onChange={e => handleInputChange(e)}
                name="bloodGroup"
                defaultValue={meInformations?.customer?.bloodGroup || ''}
              />
            </InputControl>

            <InputControl>
              <small>Peso</small>
              <input
                type="number"
                placeholder="0"
                onChange={e => handleInputChange(e)}
                name="weight"
                defaultValue={meInformations?.customer?.weight}
              />
            </InputControl>

            <InputControl>
              <small>Pressão Sistólica</small>
              <input
                type="number"
                placeholder="0"
                onChange={e => handleInputChange(e)}
                name="systolicPressure"
                defaultValue={meInformations?.customer?.systolicPressure}
              />
            </InputControl>

            <InputControl>
              <small>Pressão Diastólica</small>
              <input
                type="number"
                placeholder="0"
                onChange={e => handleInputChange(e)}
                name="diastolicPressure"
                defaultValue={meInformations?.customer?.diastolicPressure}
              />
            </InputControl>

            <InputControl>
              <small>Altura</small>
              <input
                type="text"
                placeholder="Altura"
                onChange={e => handleInputChange(e)}
                name="height"
                defaultValue={meInformations?.customer?.height}
              />
            </InputControl>

            <Button
              message="Salvar"
              isLoading={isLoading}
              disabled={Object.keys(formInputs).length === 0}
            />
          </MyDataForm>
        </MyDataModalContent>
      </MyDataModal>

      <BabyDataModal isVisible={isVisibleBabyModal}>
        <BabyDataModalContent>
          <span onClick={() => setIsVisibleBabyModal(false)}>
            <RiArrowLeftSLine />
            Voltar
          </span>

          <h3>Dados do bebê</h3>
          <small>Informações do seu bebê</small>

          <BabyDataForm onSubmit={e => handleChildrenUpdate(e)}>
            <InputControl>
              <small>Nome do seu bebê (opcional)</small>
              <input
                type="text"
                placeholder="Nome do seu bebê"
                onChange={e => handleInputChange(e)}
                name="name"
                defaultValue={childrenInformations?.name || ''}
              />
            </InputControl>

            <InputControl>
              {babyData?.attributes?.cpf ? (
                <input
                  type="text"
                  placeholder="CPF"
                  onChange={e => handleInputChange(e)}
                  name="cpf"
                  defaultValue={childrenInformations?.cpf || ''}
                  disabled
                />
              ) : (
                <InputMask
                  mask="999.999.999-99"
                  placeholder="Digite o seu CPF"
                  className="cpfInputMask"
                  name="cpf"
                  onChange={e => {
                    handleInputChange(e);
                  }}
                />
              )}
            </InputControl>

            <InputControl>
              <small>Data de nascimento do bebê (opcional)</small>
              <input
                type="date"
                placeholder="..."
                onChange={e => handleInputChange(e)}
                name="birthDate"
                defaultValue={childrenInformations?.birthDate || ''}
              />
            </InputControl>
            <InputControl>
              <small>Código para sua rede de apoio:</small>
              <CustomInput>
                {babyData?.attributes?.code ? (
                  <>
                    <input
                    type="text"
                    placeholder="Código para sua rede de apoio"
                    name="code"
                    defaultValue={babyData?.attributes?.code || ''}
                    readOnly
                    disabled
                  />
                  <RiFileCopyLine onClick={() => handleCopyClipboard(babyData?.attributes?.code)} />
                  </>
                ) : (
                  <input
                    type="text"
                    placeholder="Código para sua rede de apoio"
                    onChange={(e) => { setNetworkCode(e?.target?.value) }}
                    name="code"
                  />
                )}
              </CustomInput>
            </InputControl>

            <small>
              Este código é gerado para que sua rede de apoio compartilhe
              informações sobre sua gestação.
            </small>

            <Button
              message="Salvar"
              isLoading={isLoading}
              disabled={!networkCode}
            />
          </BabyDataForm>
        </BabyDataModalContent>
      </BabyDataModal>

      <ChallengesHistoryModal isVisible={isVisibleHistoryModal}>
        <ChallengesHistoryModalContent>
          <span onClick={() => setIsVisibleHistoryModal(false)}>
            <RiArrowLeftSLine />
            Voltar
          </span>

          <h3>Histórico de desafios</h3>
          <small>Veja o seu histórico</small>

          <HistoryList>
            {challengeHistory?.length ? challengeHistory?.map((item: any) => (
              <HistoryItem key={item?.id}>
                <HistoryItemIcon variant={item?.attributes?.challengeType?.data?.id}>
                  {getExerciseIcon[item?.attributes?.challengeType?.data?.attributes?.type]}
                </HistoryItemIcon>
                Realizado dia{' '}
                <b>
                  {new Date(item?.attributes?.createdAt).toLocaleString(
                    'pt-br',
                  )}
                </b>
              </HistoryItem>
            )) : (
              <small>Nenhum desafio foi realizado ainda..</small>
            )}
          </HistoryList>
        </ChallengesHistoryModalContent>
      </ChallengesHistoryModal>

      {isVisibleToast && (
        <Toast
          type="success"
          message="Código copiado para a área de transferência."
          visible={isVisibleToast}
        />
      )}
    </>
  );
};

export default PregnantWallet;
