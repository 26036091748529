import {
  useState,
  createContext,
  useContext,
  FC,
  ReactNode,
} from 'react';

interface WelcomedReturn {
  welcomed: boolean;
  visit: () => void;
  }

export function useWelcomed(): WelcomedReturn {
  const [welcomed, setWelcomed] = useState(() => !!localStorage.getItem('@App:welcomed'));

  function visit(): void {
    localStorage.setItem('@App:welcomed', '1');
    setWelcomed(true);
  }

  return { welcomed, visit };
}

export function useWelcomed2(): {
  welcomed: boolean;
  visit: () => void;
  WelcomeProvider: FC<ReactNode>;
  WelcomeConsumer: React.Consumer<{
      welcomed: boolean;
      visit: () => void;
  }>} {
  const [_welcomed, _setWelcomed] = useState(() => !!localStorage.getItem('@App:welcomed'));

  function visitFunc(): void {
    localStorage.setItem('@App:welcomed', '1');
    _setWelcomed(true);
  }

  const WelcomeContext = createContext({ welcomed: _welcomed, visit: visitFunc });

  const WelcomeProvider: FC = ({ children }) => WelcomeContext.Provider({
    value: { welcomed: _welcomed, visit: visitFunc },
    children,
  });

  const { welcomed, visit } = useContext(WelcomeContext);

  return {
    welcomed,
    visit,
    WelcomeProvider,
    WelcomeConsumer: WelcomeContext.Consumer,
  };
}
