import React, { ReactElement } from 'react';

import {
  RiArrowLeftSLine,
  RiCalendarCheckFill,
  RiSearchLine,
  RiDeleteBin7Line,
  RiFilter3Fill,
  RiArrowUpDownLine,
  RiStarFill,
} from 'react-icons/ri';

import {
  Container,
  ModalContainer,
  ModalHeader,
  ModalFilters,
  SearchInput,
  FiltersRow,
  FiltersButton,
  OrderButton,
  ModalContent,
  SessionList,
  SessionCard,
  SessionCardHeader,
  HeaderNavigation,
  ScheduledTag,
  SessionCardProfessional,
  ProfissionalImage,
  ProfissionalTexts,
  SessionStatus,
  StatusTag,
  InformationRow,
} from './styles';

interface SessionModalProps {
  handleToggleModal?: any;
}

const SessionModal = ({ handleToggleModal }: SessionModalProps): ReactElement => (
  <Container>
    <ModalContainer>
      <ModalHeader>
        <HeaderNavigation>
          <button type="button" onClick={handleToggleModal}>
            <RiArrowLeftSLine />
          </button>

          <h1>Sessões</h1>
        </HeaderNavigation>

        <ModalFilters>
          <SearchInput>
            <RiSearchLine />
            <input
              type="text"
              placeholder="Busque pelo nome do profissional..."
            />
          </SearchInput>
          <FiltersRow>
            <FiltersButton>
              Filtros
              <RiFilter3Fill />
            </FiltersButton>
            <OrderButton>
              Ordenar
              <RiArrowUpDownLine />
            </OrderButton>
          </FiltersRow>
        </ModalFilters>
      </ModalHeader>

      <ModalContent>
        <h3>Histórico</h3>

        <SessionList>
          <SessionCard>
            <SessionCardHeader>
              <span>Seg. 26 de fevereiro 2022</span>
              <button type="button">
                <RiDeleteBin7Line />
              </button>
            </SessionCardHeader>

            <SessionCardProfessional>
              <ProfissionalImage src="https://randomuser.me/api/portraits/women/90.jpg" />
              <ProfissionalTexts>
                <h4>Adriana de Carvalho</h4>
                <small>Pediatra Neonato</small>
                <span>
                  <RiStarFill />
                  4,5
                  <b>(2 avaliações)</b>
                </span>
              </ProfissionalTexts>
            </SessionCardProfessional>

            <SessionStatus>
              Status do pedido:
              <StatusTag>
                Pago
              </StatusTag>
            </SessionStatus>

            <InformationRow>
              Forma de pagamento:
              <b>VISA Classic Final 0102</b>
            </InformationRow>

            <InformationRow>
              Total do pedido:
              <b>R$ 282,10</b>
            </InformationRow>

            <ScheduledTag>
              <RiCalendarCheckFill />
              Sessão agendada para:
              <b>30/01 às 09:00</b>
            </ScheduledTag>

            <button type="button">Ver detalhes da sessão</button>
          </SessionCard>
        </SessionList>
      </ModalContent>
    </ModalContainer>
  </Container>
);
export default SessionModal;
