import {
  defaultRegular,
  defaultMediumText,
  defaultSemiBoldText,
  defaultRegularBody,
  defaultBoldButton,
  defaultRegularOption,
  defaultSemiBoldSmallTitle,
  defaultSemiBoldMediumTitle,
  defaultMediumMediumTitle,
  defaultSemiBoldTitle,
  defaultSemiBoldLargeTitle,
  defaultSemiBoldHeadline,
} from './mixins';

const defaultTheme = {
  colors: {
    primary: '#357BF8',
    primaryLight10: '#568FFA',
    primaryLight20: '#71A1FD',
    primaryLight30: '#94B9FF',
    primaryLight50: '#C7DAFF',
    primaryDark10: '#7584A2',
    primaryDark20: '#5E6980',
    primaryDark30: '#4B5566',
    primaryDark50: '#353C48',
    neutral: '#959595',
    neutralLight10: '#B8B8B8',
    neutralLight20: '#CFCFCF',
    neutralLight30: '#E6E6E6',
    neutralLight50: '#FFFFFF',
    neutralDark10: '#727272',
    neutralDark20: '#434343',
    neutralDark30: '#1B1B1B',
    neutralDark50: '#000000',
    opacityPrimary: 'rgba(53, 123, 248, 0.1)',
    opacityPrimaryDark50: 'rgba(53, 60, 72, 0.1)',
    opacityPrimaryLight50: 'rgba(255, 255, 255, 0.2)',
    secondary: '#51D46E',
    black: '#1B1B1B',
    background: '#F5F7FB',
    white: '#FFFFFF',
    softGreen: '#85F09D',
    purple: '#A667F6',
    pink: '#F76B8D',
  },
  texts: {
    defaultRegular,
    defaultMediumText,
    defaultSemiBoldText,
    defaultRegularBody,
    defaultRegularOption,
    defaultBoldButton,
    defaultSemiBoldSmallTitle,
    defaultSemiBoldMediumTitle,
    defaultMediumMediumTitle,
    defaultSemiBoldTitle,
    defaultSemiBoldLargeTitle,
    defaultSemiBoldHeadline,
  },
  gradients: {
    gradient: 'linear-gradient(270deg, #EEF4FF -48.6%, #D7E4FE 104.47%)',
  },
  icons: {
    love: '#FD5E5A',
    rating: '#FFD30E',
  },
  toasts: {
    success: '#51D46E',
    error: '#FD5E5A',
  },
  transitions: {
    default: '180ms ease-in-out',
  },
  shadows: {
    default: '0px 100px 80px rgba(53, 123, 248, 0.07), 0px 12.5216px 10.0172px rgba(53, 123, 248, 0.035)',
  },
};

export default defaultTheme;
