import styled, { css } from 'styled-components';

import TasksBg from '../../assets/TasksBg.png';

export const Container = styled.div`
  padding: 12px 32px 0px 32px;
  overflow: hidden;
`;

export const Header = styled.button`
  ${({ theme }) => css`
    ${theme.texts.defaultRegularBody};
    line-height: 0;
    margin-top: 2.4rem;
    background-color: transparent;
    color: ${theme.colors.primaryDark50};

    display: flex;
    align-items: center;

    > img {
      margin-right: 4px;
    }
  `}
`;

export const MainContent = styled.div`
  ${({ theme }) => css`
    margin-top: 4.25rem;

    > h1 {
      ${theme.texts.defaultSemiBoldHeadline};
    }
  `}
`;

export const Form = styled.form`
  > button {
    margin-top: 20px;
  }
`;

export const InputControl = styled.div`
  ${({ theme }) => css`
    margin-top: 20px;

    display: flex;
    flex-direction: column;

    > span {
      margin-bottom: 14px;
      ${theme.texts.defaultRegularBody};

      display: flex;
      align-items: center;
      justify-content: space-between;

      > small {
        color: ${theme.colors.primaryDark50};
        ${theme.texts.defaultSemiBoldSmallTitle};
        opacity: 0.4;
        cursor: pointer;
      }
    }
  `}
`;

export const SocialLogin = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: 20px;

    > small {
      color: ${theme.colors.primaryDark50};
      ${theme.texts.defaultSemiBoldSmallTitle};
      opacity: 0.4;
    }
  `}
`;

export const SocialButtons = styled.div`
  ${({ theme }) => css`
    display: flex;

    margin-top: 12px;
    width: 100%;

    > button {
      border-radius: 10px;
      border: 1px solid ${theme.colors.neutralLight30};
      background-color: transparent;
      flex: 1;
      height: 50px;
      opacity: 0.4;

      transition: ${theme.transitions.default};

      /* &:active,
      &:hover {
        border-color: ${theme.colors.primaryDark50};
      } */

      &:first-child {
        margin-right: 12px;
      }
    }
  `}
`;

export const RegisterToast = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.neutralLight50};
    border-radius: 10px;
    box-shadow: ${theme.shadows.default};
    max-height: 52px;
    max-width: 317px;
    padding: 18px;
    margin: 0 auto;
    ${theme.texts.defaultMediumMediumTitle};
    line-height: 16px;

    display: flex;
    align-items: center;
    justify-content: center;

    position: fixed;
    bottom: 5rem;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    z-index: 10;

    > a {
      color: ${theme.colors.primary};
      background-color: transparent;
      margin-left: 3px;
      transition: ${theme.transitions.default};

      &:hover {
        opacity: 0.8;
      }
    }
  `}
`;

export const ImageBackground = styled.div`
  position: relative;
  width: 100%;
  height: 309px;
  margin: 52px 0 0 0;

  display: flex;
  justify-content: center;

  > img {
    width: 418px;
  }
`;

export const ExercisesList = styled.div``;

export const ExerciseCard = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.neutralLight50};
    box-shadow: 0px 100px 80px rgba(53, 123, 248, 0.07),
      0px 12.5216px 10.0172px rgba(53, 123, 248, 0.035);
    border-radius: 16px;
    padding: 24px;

    display: flex;
    flex-direction: column;

    > h4 {
      ${theme.texts.defaultSemiBoldTitle};
      max-width: 278px;
      color: ${theme.colors.primaryDark50};
      margin-bottom: 8px;
    }

    > p {
      ${theme.texts.defaultRegular};
      color: ${theme.colors.primaryDark50};
      max-width: 278px;
      margin-bottom: 16px;
    }

    > img {
      max-width: 158px;
      margin: 0 auto;
      margin-bottom: 24px;
    }
  `};
`;

export const ExerciseCardHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    > button {
      background-color: ${theme.colors.opacityPrimary};
      border-radius: 14px;
      height: 34px;
      width: 34px;
      color: ${theme.colors.primary};
    }
  `};
`;

export const Tag = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.primary};
    border-radius: 6px;
    color: ${theme.colors.neutralLight50};
    font-size: 1.1rem;
    line-height: 1.5rem;
    font-weight: 500;
    padding: 4px 8px;
    text-transform: uppercase;
    cursor: pointer;
  `};
`;

export const ExercisesHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 14px 0;

    > h3 {
      color: ${theme.colors.primaryDark50};
      font-weight: 400;
      font-size: 1.1rem;
      line-height: 1.5rem;
    }

    > button {
      background-color: transparent;
      color: ${theme.colors.primaryDark50};
      font-size: 1.1rem;
      font-weight: 400;
      line-height: 1.5rem;
    }
  `};
`;

export const UserHeader = styled.div`
  padding: 14px 0 10px 0;
`;

export const UserHeaderTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const UserHeaderLeftContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    > small {
      color: ${theme.colors.primaryDark50};
      ${theme.texts.defaultRegular};
    }

    > b {
      ${theme.texts.defaultSemiBoldHeadline};
      color: ${theme.colors.primaryDark50};

      > span {
        ${theme.texts.defaultRegular};
        color: ${theme.colors.primaryDark50};
        opacity: 0.2;
        margin-left: 6px;
      }
    }
  `};
`;

export const UserImage = styled.div`
  width: 58px;
  height: 58px;
  background: rgba(53, 123, 248, 0.1);
  border-radius: 14px;
  margin-left: 18px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PointsControl = styled.div`
  margin-top: 14px;
`;

export const PointsProgress = styled.div`
  display: flex;
  align-items: center;
`;

export const ProgressBar = styled.div`
  margin: 6px 0;
  height: 5px;
  background-color: rgba(199, 218, 255, 0.4);
  border-radius: 100px;

  position: relative;

  > span {
    width: 20%;
    height: 100%;
    background-color: #c29873;
    border-radius: 100px;

    position: absolute;
  }
`;

export const PointsTexts = styled.div`
  ${({ theme }) => css`
    flex: 1;
    margin-left: 13px;

    > small {
      ${theme.texts.defaultMediumText};
      color: ${theme.colors.primaryDark50};
      opacity: 0.3;

      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    > span {
      ${theme.texts.defaultMediumText};
      color: ${theme.colors.primaryDark50};

      display: flex;
      align-items: center;
      justify-content: space-between;

      > b {
        ${theme.texts.defaultSemiBoldMediumTitle};
        font-weight: 600;

        > em {
          font-style: normal;
        }
      }
    }
  `};
`;

export const UserHeaderIcons = styled.div`
  ${({ theme }) => css`
    margin-left: auto;

    display: flex;
    align-items: center;

    > svg {
      font-size: 1.8rem;
      color: ${theme.colors.primaryDark50};

      :first-child {
        margin-right: 18px;
      }
    }
  `};
`;

export const ChallengesSection = styled.div`
  margin-top: 14px;
`;

export const ChallengeSectionHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    color: ${theme.colors.primaryDark50};
    margin-bottom: 14px;

    > span {
      display: flex;
      align-items: center;

      cursor: pointer;
      ${theme.texts.defaultRegular};
    }

    > small {
      ${theme.texts.defaultRegular};
    }
  `};
`;

interface ChallengeCardProps {
  completed?: boolean;
}

export const ChallengeCard = styled.div<ChallengeCardProps>`
  ${({ theme, completed }) => css`
    border-radius: 16px;
    background-color: ${theme.colors.neutralLight50};
    box-shadow: ${theme.shadows.default};
    padding: 24px;
    margin-bottom: 20px;

    display: flex;
    flex-direction: column;

    > img {
      max-width: 158px;
      margin: 0 auto;
      margin-bottom: 24px;
    }

    > h3 {
      color: ${theme.colors.primaryDark50};
      ${theme.texts.defaultSemiBoldTitle};
      margin-bottom: 8px;
      max-width: 278px;
    }

    > p {
      color: ${theme.colors.primaryDark50};
      ${theme.texts.defaultRegular};
      max-width: 278px;
    }

    > button {
      background-color: ${theme.colors.primary};
      color: ${theme.colors.neutralLight50};
      ${theme.texts.defaultMediumMediumTitle};
      height: 44px;
      border-radius: 10px;
      margin-top: 16px;

      transition: ${theme.transitions.default};

      &:hover {
        opacity: 0.8;
      }

      > svg {
        margin-left: 6px;
      }
    }

    ${completed
    && css`
      > button {
        background-color: ${theme.colors.secondary};
      }

      ${ChallengeCardHeader} {
        > button {
          background-color: ${theme.colors.secondary};
          color: ${theme.colors.neutralLight50};
        }

        ${ChallengeCardTag} {
          background-color: ${theme.colors.secondary};
        }
      }
    `}
  `};
`;

export const ChallengeCardHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    > button {
      background: rgba(53, 123, 248, 0.1);
      border-radius: 14px;
      width: 34px;
      height: 34px;
      color: ${theme.colors.primary};
      font-size: 1.6rem;

      display: flex;
      align-items: center;
      justify-content: center;

      transition: ${theme.transitions.default};

      &:hover {
        opacity: 0.8;
      }
    }
  `};
`;

export const ChallengeCardTag = styled.span`
  ${({ theme }) => css`
    ${theme.texts.defaultMediumText};
    text-transform: uppercase;
    color: ${theme.colors.neutralLight50};
    background-color: ${theme.colors.primary};
    border-radius: 6px;
    padding: 4px 8px;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
  `};
`;

export const ExercisesCardCarousel = styled.div`
  display: flex;
  overflow: scroll;
`;

interface ExerciseCarouselItemProps {
  variant?: string;
}

export const ExerciseCarouselItem = styled.div<ExerciseCarouselItemProps>`
  ${({ theme, variant }) => css`
    display: flex;
    flex-direction: column;
    height: 152px;
    max-width: ;
    min-width: 326px;
    padding: 22px 22px 25px 22px;
    border-radius: 16px;

    :not(:last-child) {
      margin-right: 14px;
    }

    > span {
      display: flex;
      align-items: center;

      margin-top: auto;
      cursor: pointer;
      color: ${theme.colors.neutralLight50};
      ${theme.texts.defaultRegular};
    }

    > small {
      ${theme.texts.defaultRegularBody};
      color: ${theme.colors.primaryDark50};
    }

    > h3 {
      ${theme.texts.defaultSemiBoldHeadline};
      color: ${theme.colors.primaryDark50};
    }

    ${variant === 'primary'
    && css`
      background: linear-gradient(270deg, #eef4ff -48.6%, #d7e4fe 104.47%);

      > span {
        color: ${theme.colors.primaryDark50};
      }
    `}

    ${variant === 'secondary'
    && css`
      background: linear-gradient(
        179.55deg,
        rgba(128, 221, 144, 0) -106.9%,
        #80dd90 69.67%
      );

      > span,
      small,
      h3 {
        color: ${theme.colors.neutralLight50};
      }
    `}

    ${variant === 'tertiary'
    && css`
      background: linear-gradient(
        179.55deg,
        rgba(247, 100, 136, 0) -106.9%,
        #f76488 69.67%
      );

      > span,
      small,
      h3 {
        color: ${theme.colors.neutralLight50};
      }
    `}
  `};
`;

export const TeleConsultationSection = styled.div`
  margin-bottom: 20px;
`;

export const TeleConsultationSectionHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    color: ${theme.colors.primaryDark50};
    margin-bottom: 14px;

    > span {
      display: flex;
      align-items: center;

      cursor: pointer;
      ${theme.texts.defaultRegular};
    }

    > small {
      ${theme.texts.defaultRegular};
    }
  `};
`;

export const TeleConsultationCard = styled.div`
  ${({ theme }) => css`
    box-shadow: ${theme.shadows.default};
    background-color: ${theme.colors.neutralLight50};
    border-radius: 16px;
  `};
`;

export const TeleConsultationCardHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    height: 185px;
    border-radius: 16px 16px 0 0;
    background: url(${TasksBg}) center center;
    background-size: cover;

    > h3 {
      color: ${theme.colors.primaryDark50};
      ${theme.texts.defaultSemiBoldHeadline};
      margin: 6px 0;
    }

    > p {
      ${theme.texts.defaultRegular};
      max-width: 192px;
    }
  `};
`;

export const ExpertItem = styled.div`
  display: flex;
  align-items: center;

  :not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const TeleConsultationIcon = styled.div`
  ${({ theme }) => css`
    width: 52px;
    height: 52px;
    border-radius: 14px;
    background-color: ${theme.colors.neutralLight50};

    display: flex;
    justify-content: center;
    align-items: center;

    > svg {
      font-size: 2rem;
      color: ${theme.colors.primary};
    }
  `};
`;

export const ExpertsList = styled.div`
  ${({ theme }) => css`
    padding: 24px;

    > button {
      border: 1px solid ${theme.colors.primary};
      background-color: transparent;
      width: 100%;
      margin-top: 20px;
      border-radius: 10px;
      height: 50px;
      color: ${theme.colors.primary};
      ${theme.texts.defaultSemiBoldSmallTitle};

      transition: ${theme.transitions.default};

      &:hover {
        opacity: 0.8;
      }

      > svg {
        margin-left: 6px;
      }
    }
  `};
`;

export const ExpertImage = styled.img`
  width: 62px;
  height: 62px;
  border-radius: 14px;
  background-color: rgba(53, 60, 72, 0.1);

  display: flex;
`;

export const ExpertTexts = styled.div`
  ${({ theme }) => css`
    margin-left: 17px;

    display: flex;
    flex-direction: column;

    > h5 {
      color: ${theme.colors.primaryDark50};
      ${theme.texts.defaultSemiBoldMediumTitle};
    }

    > small {
      ${theme.texts.defaultRegular};
      color: ${theme.colors.primaryDark50};
      margin: 6px 0;

      display: flex;

      > em {
        margin-left: 6px;
        font-style: normal;
        color: ${theme.icons.rating};
        font-weight: 600;

        display: flex;
        align-items: center;

        > svg {
          margin-right: 2px;
        }
      }

      > b {
        margin-left: 4px;
        ${theme.texts.defaultRegular};
        color: ${theme.colors.primaryDark50};
        opacity: 0.5;
      }
    }
  `};
`;

export const ExpertTag = styled.div`
  ${({ theme }) => css`
    background: rgba(53, 123, 248, 0.1);
    border-radius: 4px;
    padding: 4px 6px;
    max-width: 80px;
    max-height: 18px;
    color: ${theme.colors.primaryDark50};
    ${theme.texts.defaultRegular};

    display: flex;
    align-items: center;
    justify-content: center;

    > b {
      margin-right: 2px;
    }
  `};
`;
