import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding: 0 32px 130px 32px;
`;

export const NestedContainer = styled.div`
  position: relative;
  padding-top: 50px;
`;

export const MainHeader = styled.div``;

export const CameraButton = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.neutralLight50};
    border-radius: 14px;
    box-shadow: 0px 100px 80px rgba(53, 123, 248, 0.07),
      0px 12.5216px 10.0172px rgba(53, 123, 248, 0.035);
    padding: 10px;
    width: 42px;
    height: 42px;
    margin-right: 10px;

    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
      font-size: 18px;
      color: ${theme.colors.primaryDark50};
    }
  `};
`;

export const UserInformations = styled.div`
  padding: 9px 0;
  margin: 6px 0 14px 0;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const UserImage = styled.div`
  width: 58px;
  height: 58px;
  background: rgba(53, 123, 248, 0.1);
  border-radius: 14px; 

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LeftContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    > button {
      background-color: transparent;
      font-size: 1.1rem;
      line-height: 1.5rem;
      color: ${theme.colors.primaryDark50};

      display: flex;
      align-items: center;

      > svg {
        margin-left: 5px;
      }
    }
  `}
`;

export const RightContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    color: ${theme.colors.primaryDark50};

    > svg {
      font-size: 1.8rem;
      margin-right: 18px;
    }
  `};
`;

export const SearchInput = styled.div`
  ${({ theme }) => css`
    position: relative;

    display: flex;
    align-items: center;

    > svg {
      color: ${theme.colors.primary};
      font-size: 1.2rem;

      position: absolute;
      left: 16px;
    }

    > input {
      background-color: ${theme.colors.neutralLight50};
      color: ${theme.colors.primaryDark50};
      border-radius: 16px;
      border: 0;
      width: 100%;
      height: 49px;
      padding: 16px;
      padding-left: 40px;
      ${theme.texts.defaultRegularBody};

      &::placeholder {
        color: ${theme.colors.primaryDark50};
        opacity: 0.2;
      }
    }
  `};
`;

export const Filters = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin: 20px 0 14px 0;

    > button {
      background-color: transparent;
      color: ${theme.colors.primaryDark50};
      font-size: 1.1rem;
      line-height: 1.5rem;

      display: flex;
      align-items: center;

      > svg {
        font-size: 1.4rem;
        margin-left: 4px;
      }
    }

    > span {
      color: ${theme.colors.primaryDark50};
      font-size: 1.1rem;
      line-height: 1.5rem;

      display: flex;
      align-items: center;

      > svg {
        margin-left: 4px;
        font-size: 1.2rem;
      }
    }
  `};
`;

export const SpecialistList = styled.div``;

export const SpecialistCard = styled.div`
  ${({ theme }) => css`
    margin-bottom: 22px;
    cursor: pointer;

    display: flex;
    align-items: center;

    > svg {
      margin-left: auto;
      font-size: 1.5rem;
      color: ${theme.colors.primaryDark50};
      opacity: 0.3;
      cursor: pointer;
    }
  `};
`;

export const SpecialistImage = styled.img`
  ${({ theme }) => css`
    width: 62px;
    height: 62px;
    border-radius: 14px;
    background-color: ${theme.colors.neutralLight50};
    margin-right: 17px;
  `};
`;

export const SpecialistTexts = styled.div`
  ${({ theme }) => css`
    ${theme.texts.defaultSemiBoldMediumTitle};
    color: ${theme.colors.primaryDark50};
  `};
`;

export const SpecialistDescription = styled.div`
  ${({ theme }) => css`
    margin: 6px 0;
    font-size: 1.1rem;
    line-height: 1.5rem;
    font-weight: normal;

    display: flex;
    align-items: center;

    > span {
      margin-left: 6px;
      color: ${theme.icons.rating};
      ${theme.texts.defaultSemiBoldText};

      display: flex;
      align-items: center;

      > svg {
        margin-right: 2px;
      }
    }

    > small {
      color: ${theme.colors.primaryDark50};
      font-size: 1.1rem;
      line-height: 1.5rem;
      opacity: 0.5;
      margin-left: 4px;
    }
  `};
`;

export const SpecialistTag = styled.div`
  ${({ theme }) => css`
    background: rgba(53, 123, 248, 0.1);
    border-radius: 4px;
    color: ${theme.colors.primaryDark50};
    font-size: 1.1rem;
    line-height: 1.5rem;
    height: 18px;
    width: 80px;
    padding: 2px 4px;
    font-weight: normal;

    display: flex;
    align-items: center;
    justify-content: center;

    > b {
      margin-right: 2px;
    }
  `};
`;

export const ComingImage = styled.div`
  /* margin: 14px 0; */

  > img {
    width: 100%;
  }
`;

export const MainHeaderTexts = styled.div`
  ${({ theme }) => css`
    margin: 14px;
    text-align: center;

    display: flex;
    flex-direction: column;
    
    > h1 {
      color: ${theme.colors.primaryDark50};
      ${theme.texts.defaultSemiBoldHeadline};
      margin: 18px 0 12px 0;
    }

    > p {
      color: ${theme.colors.primaryDark50};
      font-size: 1.1rem;
      line-height: 1.5rem;
      font-weight: normal;
      margin-bottom: 12px;
    }
  `};
`;
