import React, { ReactElement, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Container, Tabs, TabItem } from './styles';

import { SectionSwitchProps } from './sectionSwitch.spec';

const SectionSwitch = ({
  tabs = [],
  activeIndex,
}: SectionSwitchProps): ReactElement => {
  const [activeTabIndex, setActiveTabIndex] = useState<number | any>(
    activeIndex,
  );
  const routeHistory = useHistory();

  function handleRedirectTo(index: number, path: string | any): void {
    routeHistory.push(path);
    setActiveTabIndex(index);
  }

  return (
    <Container>
      <Tabs>
        {tabs?.map((tab, index) => (
          <TabItem
            active={activeTabIndex === index}
            key={tab?.title}
            onClick={() => handleRedirectTo(index, tab?.path)}
          >
            {tab?.icon}
            <span>{tab?.title}</span>
          </TabItem>
        ))}
      </Tabs>
    </Container>
  );
};

export default SectionSwitch;
