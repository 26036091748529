import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    margin-top: 36px;
    height: 86vh;

    > h1 {
      color: ${theme.colors.primaryDark50};
      ${theme.texts.defaultSemiBoldHeadline};
      margin-bottom: 20px;
    }
  `}
`;

export const BackButton = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.primaryDark50};
    ${theme.texts.defaultRegularBody};
    margin-bottom: 20px;
    cursor: pointer;

    display: flex;
    align-items: center;

    > svg {
      font-size: 17px;
    }
  `};
`;

export const SignUpForm = styled.form``;

export const InputControl = styled.div`
  ${({ theme }) => css`
    margin-bottom: 20px;

    > small {
      color: ${theme.colors.primaryDark50};
      ${theme.texts.defaultRegularBody};
      margin-bottom: 14px;

      display: flex;
    }

    > input {
      border: 1px solid rgba(53, 60, 72, 0.1);
      border-radius: 10px;
      height: 50px;
      width: 100%;
      padding: 22px;
      background-color: transparent;
    }
  `}
`;

export const CalculateToast = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.opacityPrimary};
    border-radius: 8px;
    box-shadow: ${theme.shadows.default};
    max-height: 39px;
    max-width: 326px;
    padding: 12px 40.5px;
    margin: 0 auto 20px;
    ${theme.texts.defaultMediumText};

    display: flex;
    align-items: center;
    justify-content: center;

    > a {
      color: ${theme.colors.primary};
      background-color: transparent;
      margin-left: 3px;
      transition: ${theme.transitions.default};

      &:hover {
        opacity: 0.8;
      }
    }
  `}
`;

export const FormGroup = styled.div`
  display: flex;
  justify-content: space-between;

  > button {
    max-width: 156px;
  }
`;
