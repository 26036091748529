import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';

import Home from '../pages/Home';
// import EstimatedCalculator from '../components/EstimatedCalculator';
import TeleConsultation from '../pages/TeleConsultation';
import SpecialistDetails from '../pages/TeleConsultation/SpecialistDetail';
import MarketPlace from '../pages/MarketPlace';
import Menu from '../pages/Menu';

const AuthenticatedRoutes: React.FC = () => (
  <BrowserRouter>
    <Route exact path="/" component={Home} />
    <Route exact path="/teleconsultation" component={TeleConsultation} />
    <Route exact path="/marketplace" component={MarketPlace} />
    <Route exact path="/menu" component={Menu} />
    <Route path="/specialist/:id" component={SpecialistDetails} />
  </BrowserRouter>
);

export default AuthenticatedRoutes;
