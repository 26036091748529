import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding: 83px 32px 0;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  ${({ theme }) => css`
    > img {
      width: 91px;
      margin-bottom: 6px;
    }

    > h2 {
      ${theme.texts.defaultSemiBoldHeadline}
      margin-bottom: 74px;
    }

    > p {
      ${theme.texts.defaultSemiBoldSmallTitle}
      color: ${theme.colors.primaryDark50};
      opacity: 0.4;
    }
  `}
`;

export const Carousel = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: 296px;
    margin: 0 auto;

    .alice-carousel {
      .alice-carousel__dots {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 73px 3px 5px;

        .alice-carousel__dots-item {
          &:not(.__custom) {
            background-color: rgba(27, 27, 27, 0.3);
            width: 6px;
            height: 6px;
          }
          &.__active {
            background-color: ${theme.colors.primaryDark50};
            width: 6px;
            height: 6px;
          }
        }
      }
      .item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        > img {
          width: 223px;
          margin-bottom: 17px;
        }
        .text {
          > h6 {
            ${theme.texts.defaultSemiBoldLargeTitle}

            margin-bottom: 12px;
            text-align: center;

            color: ${theme.colors.primaryDark50};
          }
          > p {
            ${theme.texts.defaultRegularOption}

            text-align: center;

            color: ${theme.colors.primaryDark50};
            opacity: 0.5;
          }
        }
      }
    }
  `}
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 34px 0 14px 0;

  > button {
    max-width: 155px;
  }
`;
