export const TRANSLATIONS_EN = {
  signIn: 'Sign In',
  today: 'Today',
  marketplace: 'Marketplace',
  teleconsultation: 'Professionals',
  back: 'Back',
  accountSignIn: 'Login to your account',
  yourDocument: 'Your document',
  typeYourDocument: 'Type your document',
  yourPassword: 'Your password',
  forgotPassword: 'Forgot your password?',
  signInAlternative: 'Or sign in with:',
  noHasAccount: "You don't have an account?",
  register: 'Register',
  welcome: 'Welcome!',
  welcomeCarousel: {
    challenge: {
      title: 'A healthy challenge for the whole family!',
      description:
        "It's 1,000 days for you to take care of your baby, building a beautiful journey full of interactivity.",
    },
    professionals: {
      title: 'Professionals ready to serve you!',
      description:
        'Teleconsultation that guides you whenever you need it! Yes, professionals available 24 hours a day.',
    },
    network: {
      title: 'A family network where everyone has fun!',
      description:
        'An ecosystem of family collaboration for everyone: parents, uncles, grandparents, cousins and anyone who wants to be part of the challenge.',
    },
    partners: {
      title: 'Amazing partners to make your journey perfect!',
      description:
        'Through our marketplace, you can purchase personalized products with exclusive discounts.',
    },
  },
  skipStep: 'Skip this step',
  tryingToGetPregnant: 'Is trying to get pregnant?',
  signTrying: 'Enter as trying',
};
