import React, { ReactElement, useState } from 'react';

import {
  CustomInput,
  LoadingCircle,
  Circle,
  InputContainer,
  Icon,
} from './styles';

import { InputProps } from './input.spec';

const Input: React.FC<InputProps> = ({
  isLoading = false,
  state = 'default',
  placeholder = '',
  icon = null,
  handleRevealText = null,
  ...rest
}): ReactElement => {
  const [isFocused, setIsFocused] = useState<boolean>(false);

  return (
    <InputContainer state={state} isFocused={isFocused}>
      <CustomInput
        {...rest}
        placeholder={placeholder}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />

      {isLoading && !icon && (
        <LoadingCircle>
          <Circle />
        </LoadingCircle>
      )}

      {icon && <Icon onClick={handleRevealText}>{icon}</Icon>}
    </InputContainer>
  );
};

export default Input;
