import React, { useState } from 'react';
import { RiArrowLeftSLine } from 'react-icons/ri';

import {
  Container, BackButton, SignUpForm, InputControl,
} from './styles';
import Button from '../../../components/Button';

import { useAuth } from '../../../contexts/AuthContext';

import customerService from '../../../services/customer';

interface RelationshipInterface {
  handlePrevStep?: any;
  handleNextStep?: any;
  handleLastStep?: any;
  handleRelationshipChange?: any;
}

const Relationship = ({
  handlePrevStep,
  handleNextStep,
  handleLastStep,
  handleRelationshipChange,
}: RelationshipInterface): any => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [relationshipBaby, setRelationshipBaby] = useState('mother');
  const storagedUser = JSON.parse(sessionStorage.getItem('@App:customerServiceData') as any);
  const { AutoLogin } = useAuth();

  async function handleStepChange(): Promise<void> {
    setIsLoading(true);

    const customStoragedUser = {
      ...storagedUser,
      type: relationshipBaby,
    };

    const responseData = (await customerService.post(customStoragedUser)).data;

    AutoLogin(responseData);

    setTimeout(() => {
      if (relationshipBaby === 'mother') {
        handleNextStep();
        return;
      }
      handleLastStep();
      setIsLoading(false);
    }, 2000);
  }

  return (
    <Container>
      <BackButton onClick={() => handlePrevStep()}>
        <RiArrowLeftSLine />
        Voltar
      </BackButton>

      <h1>Informe seu grau de parentesco com o bebê</h1>

      <SignUpForm>
        <InputControl>
          <small>Grau de parentesco</small>
          <select
            name="relationship"
            onChange={(e) => {
              handleRelationshipChange(e?.target?.value);
              setRelationshipBaby(e?.target?.value);
            }}
          >
            <option value="mother">Mãe</option>
            <option value="father">Pai</option>
            <option value="grandmother">Avó</option>
            <option value="grandfather">Avô</option>
          </select>
        </InputControl>

        <Button
          message="Avançar"
          onClick={() => handleStepChange()}
          isLoading={isLoading}
        />
      </SignUpForm>
    </Container>
  );
};

export default Relationship;
