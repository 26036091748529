import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';

// import { Link } from 'react-router-dom';
import {
  RiArrowRightSLine,
  RiHomeSmile2Line,
  RiUserSmileLine,
  RiOpenArmLine,
  RiTeamLine,
  RiLogoutBoxRLine,
} from 'react-icons/ri';

import PregnantWallet from '../../components/PregnantWallet';
import Navbar from '../../components/Navbar';

import {
  Container,
  NestedContainer,
  MenuItems,
  MenuItemIcon,
  MenuItem,
} from './styles';
import { useAuth } from '../../contexts/AuthContext';

const navbarTabs = [
  {
    icon: <RiHomeSmile2Line />, title: 'Hoje', disabled: false, to: '/',
  },
  { icon: <RiOpenArmLine />, title: 'Bebê', disabled: true },
  { icon: <RiUserSmileLine />, title: 'Eu', disabled: true },
  { icon: <RiTeamLine />, title: 'Grupos', disabled: true },
];

const Menu = (): ReactElement => {
  const { Logout } = useAuth();
  const routerHistory = useHistory();

  function handleLogout(): void {
    routerHistory.push('/');
    Logout();
  }

  return (
    <NestedContainer>
      <PregnantWallet />
      <Container>
        <h2>Menu</h2>
        <p>Navegue por todas funcionalidades do app</p>

        <MenuItems>
          <MenuItem onClick={() => handleLogout()}>
            <MenuItemIcon>
              <RiLogoutBoxRLine />
            </MenuItemIcon>
            Sair
            <RiArrowRightSLine />
          </MenuItem>
        </MenuItems>
      </Container>

      <Navbar navbarTabs={navbarTabs} activeIndex={4} />
    </NestedContainer>
  );
};

export default Menu;
