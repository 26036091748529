import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    margin-top: 36px;
    height: 86vh;

    > h1 {
      color: ${theme.colors.primaryDark50};
      ${theme.texts.defaultSemiBoldHeadline};
      margin-bottom: 20px;
    }
  `}
`;

export const BackButton = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.primaryDark50};
    ${theme.texts.defaultRegularBody};
    margin-bottom: 20px;
    cursor: pointer;

    display: flex;
    align-items: center;

    > svg {
      font-size: 17px;
    }
  `};
`;

export const SignUpForm = styled.div``;

export const InputControl = styled.div`
  ${({ theme }) => css`
    margin-bottom: 20px;

    > small {
      color: ${theme.colors.primaryDark50};
      ${theme.texts.defaultRegularBody};
      margin-bottom: 14px;

      display: flex;
    }

    > select {
      border: 1px solid rgba(53, 60, 72, 0.1);
      border-radius: 10px;
      height: 50px;
      padding: 14px 22px;
      width: 100%;
      background-color: transparent;
      color: ${theme.colors.primaryDark50};
      -webkit-appearance: none;
      -moz-appearance: none;
      cursor: not-allowed;

      &::placeholder {
        color: ${theme.colors.primaryDark50};
        opacity: 0.2;
      }
    }
  `}
`;

export const RegisterToast = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.opacityPrimary};
    border-radius: 8px;
    box-shadow: ${theme.shadows.default};
    max-height: 39px;
    max-width: 326px;
    padding: 12px 35.5px;
    margin: 0 auto;
    ${theme.texts.defaultMediumText};

    display: flex;
    align-items: center;
    justify-content: center;

    position: fixed;
    bottom: 5.25rem;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    z-index: 10;

    > a {
      color: ${theme.colors.primary};
      background-color: transparent;
      margin-left: 3px;
      transition: ${theme.transitions.default};

      &:hover {
        opacity: 0.8;
      }
    }
  `}
`;
