/* eslint-disable */
import React, { ReactElement, useEffect, useState } from 'react';
import moment from 'moment';

import {
  RiArrowLeftSLine,
  RiWaterFlashLine,
  RiArrowRightSLine,
  RiExternalLinkLine,
  RiCheckFill,
} from 'react-icons/ri';

import ExerciseIllustrationRed from '../../assets/ExerciseIllustrationRed.svg';
import MeasuresSmallIllustration from '../../assets/MeasuresSmallIllustration.svg';

import challengesService from '../../services/challenges';
import customerService from '../../services/customer';

import Toast from '../Toast';

import {
  Container,
  ExerciseHeader,
  Tag,
  ExercisePostHeader,
  Brand,
  ExerciseContent,
  SectionHeader,
  OtherExerciseCard,
  OtherExerciseCardHeader,
  OtherExerciseTag,
  OtherExerciseTexts,
  MainQuantityInput,
  InputQuantity,
  QuantitiesButton,
  QuantityControl,
  CompletedItem,
  Progress,
  ProgressButton,
  ProgressText,
  MeditationCard,
  RoundedPercentage,
} from './styles';

interface ExerciseModalProps {
  visibleExerciseModal?: boolean;
  toggleVisibleModal?: any;
  handleDetailModal?: any;
}

const WaterModal = ({
  visibleExerciseModal,
  toggleVisibleModal,
  handleDetailModal
}: ExerciseModalProps): ReactElement => {
  const customerInformations = localStorage.getItem('@App:customerId') as any;
  const storagedWaterQuantity = localStorage.getItem('@App:waterQuantityControl') as any;
  const [quantityNumber, setQuantityNumber] = useState<any>(0);
  const [storageQuantityNumber, setStorageQuantityNumber] = useState(JSON.parse(storagedWaterQuantity)) as any;
  const [isFinished, setIsFinished] = useState<boolean>(false);
  const [completedChallenges, setCompletedChallenges] = useState<any>([]);
  const [isVisibleToast, setIsVisibleToast] = useState<boolean>(false);

  useEffect(() => {
    const clear = async () => { // Create a new async function: clear
      // write your cleanup code here
      await getCompletedChallenges();
    };
    clear() // Call the clear() function
  }, []);

  async function handleQuantityFinished(): Promise<void> {
    try {
      const dateCopy = new Date();
      const expiryDate = moment(dateCopy).endOf("day").toDate().getTime();

      if (quantityNumber > 0) {
        localStorage.setItem('@App:waterQuantityControl', JSON.stringify({
          value: storageQuantityNumber?.value ? quantityNumber + storageQuantityNumber?.value : quantityNumber,
          expiry: expiryDate,
        }));
  
        setStorageQuantityNumber(JSON.parse(localStorage.getItem('@App:waterQuantityControl') as any));
  
        const challengeBody = {
          challengeType: 3,
          customer: JSON.parse(customerInformations),
          status: storageQuantityNumber?.value >= 2000 || quantityNumber >= 2000 || quantityNumber + storageQuantityNumber?.value >= 2000 ? 'completed' : 'leave',
          ml: storageQuantityNumber?.value.toString(),
        }
  
        await challengesService.post(challengeBody);
  
        if (storageQuantityNumber?.value >= 2000 || quantityNumber >= 2000 || quantityNumber + storageQuantityNumber?.value >= 2000) {
          setIsFinished(true);
        }
  
        setIsVisibleToast(true);
        setTimeout(() => {
          setIsVisibleToast(false);
        }, 2500);
      }

    } catch {
      console.log("Can't create a challenge.");
    }
  }

  function handleQuantityChange(quantity: number): void {
    setQuantityNumber(quantityNumber + quantity);
  }

  async function getCompletedChallenges(): Promise<any> {
    try {
      const { points, children, challenges } = (await customerService.getMe())
        ?.customer;

      setCompletedChallenges(challenges || []);
    } catch {
      console.log("Can't get challenge informations, try again!");
    }
  }

  function checkHasCompleted(type: string) {
    const filteredChallenge = completedChallenges.filter(
      (item: any) => item?.key === type && item?.status === 'completed',
    );

    return filteredChallenge?.length > 0;
  }


  return (
    <Container visible={visibleExerciseModal} finished={isFinished}>
      <ExerciseHeader>
        <button type="button" onClick={toggleVisibleModal}>
          <RiArrowLeftSLine />
        </button>
        <Tag>Alimentação</Tag>
      </ExerciseHeader>

      <ExerciseContent>
        <ExercisePostHeader>
          <Brand>
            <RiWaterFlashLine />
          </Brand>
          <h1>Beber ao menos 2 litros de água no dia</h1>
        </ExercisePostHeader>

        {isFinished || storageQuantityNumber?.value >= 2000 ? (
          <CompletedItem>
            Desafio concluído!
            <RiCheckFill />
          </CompletedItem>
        ) : (
          <>
            <small>Adicione o quanto você ja consumiu:</small>
            <QuantityControl>
              <MainQuantityInput>
                <InputQuantity>
                  <input
                    type="number"
                    value={quantityNumber}
                    onChange={e => {
                      setQuantityNumber(Number(e?.target.value));
                    }}
                  />
                  <span>ml</span>
                </InputQuantity>
                <button type="button" onClick={handleQuantityFinished} disabled={!quantityNumber}>
                  Adicionar
                </button>
              </MainQuantityInput>

              <QuantitiesButton>
                <button type="button" onClick={() => handleQuantityChange(250)}>
                  + 250 ml
                </button>
                <button type="button" onClick={() => handleQuantityChange(500)}>
                  + 500 ml
                </button>
                <button
                  type="button"
                  onClick={() => handleQuantityChange(1000)}
                >
                  + 1 L
                </button>
              </QuantitiesButton>
            </QuantityControl>

            <Progress>
              <MeditationCard>
                <RoundedPercentage>
                  <svg viewBox="0 0 36 36" className="circular-chart">
                    <path
                      className="circle-bg"
                      d="M18 2.0845
                      a 15.9155 15.9155 0 0 1 0 31.831
                      a 15.9155 15.9155 0 0 1 0 -31.831"
                    />
                    <path
                      className="circle"
                      strokeDasharray={`${((storageQuantityNumber?.value || quantityNumber / 100) * 0.05).toFixed(
                        0,
                      )}, 100`}
                      d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831"
                    />
                    <text x="18" y="20.5" className="percentage">
                      {((storageQuantityNumber?.value || 0 / 100) * 0.05).toFixed(0)}%
                    </text>
                  </svg>
                </RoundedPercentage>

                <ProgressText>
                  Total consumido:
                  <b>{storageQuantityNumber?.value || 0 } ml</b>
                </ProgressText>

                <img
                  src={MeasuresSmallIllustration}
                  alt="MeasuresSmallIllustration"
                />
              </MeditationCard>
            </Progress>
          </>
        )}
      </ExerciseContent>

      <SectionHeader>
        Outros desafios
        {/* <span>
          Ver tudo
          <RiArrowRightSLine />
        </span> */}
      </SectionHeader>

      <OtherExerciseCard>
        <OtherExerciseCardHeader>
          <OtherExerciseTag>Exercício</OtherExerciseTag>
          {/* <span>
            <RiExternalLinkLine />
          </span> */}
        </OtherExerciseCardHeader>

        <img src={ExerciseIllustrationRed} alt="ExerciseIllustrationRed" />

        <OtherExerciseTexts>
          <h3>Fazer 5 minutos de exercícios</h3>
          <p>
            Exercícios simples que lhe ajudarão a ficar mais relaxada com sua
            nova rotina.
          </p>
          {checkHasCompleted('exercise') ? (
            <button type="button" disabled>
              Realizado!
              <RiCheckFill />
            </button>
          ) : (
            <button type="button" onClick={handleDetailModal}>Começar!</button>
          )}
        </OtherExerciseTexts>
      </OtherExerciseCard>

      <Toast visible={isVisibleToast} message="Desafio salvo com sucesso!" type="success" />
    </Container>
  );
};

export default WaterModal;
