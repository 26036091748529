import React, { useState } from 'react';
// import { useHistory } from 'react-router-dom';
// import InputMask from 'react-input-mask';

import {
  Container, SignUpForm, InputControl,
} from './styles';

import Button from '../../../components/Button';
import Input from '../../../components/Input';
import Toast from '../../../components/Toast';

import childrenService from '../../../services/children';

interface BabyDataInterface {
  handleNextStep?: any;
}

const BabyData = ({ handleNextStep }: BabyDataInterface): any => {
  // const routerHistory = useHistory() as any;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formInputs, setFormInputs] = useState<any>({});
  const [isVisibleToast, setIsVisibleToast] = useState<boolean>(false);

  function handleInputChange(event: any): void {
    const { name, value } = event?.target;

    setFormInputs({
      ...formInputs,
      [name]: value,
    });
  }

  async function handleBabyRegister(event: any): Promise<any> {
    event.preventDefault();
    setIsLoading(true);

    setTimeout(async () => {
      const customerInformations = localStorage.getItem(
        '@App:customer',
      ) as any;

      try {
        const babyDataResponse = await childrenService.post({
          data: { ...formInputs, customers: JSON.parse(customerInformations)?.id },
        });

        localStorage.setItem('@App:babyCode', babyDataResponse?.data?.data?.attributes?.code);

        handleNextStep();
      } catch {
        setIsVisibleToast(true);

        setTimeout(() => {
          setIsVisibleToast(false);
        }, 2500);
      }

      setIsLoading(false);
    }, 2000);
  }

  return (
    <Container>
      <h1>Dados da gestação</h1>

      <SignUpForm onSubmit={(e) => handleBabyRegister(e)}>
        <InputControl>
          <small>Nome do seu bebê (opcional)</small>
          <Input
            placeholder="Digite o nome"
            name="name"
            onChange={(e) => handleInputChange(e)}
          />
        </InputControl>

        <InputControl>
          <small>Última menstruação</small>
          <input
            type="date"
            name="lastPeriod"
            onChange={(e) => handleInputChange(e)}
            required
          />
        </InputControl>

        <Button message="Concluir" isLoading={isLoading} />
      </SignUpForm>

      {isVisibleToast && (
      <Toast
        type="error"
        message="Ocorreu um erro no servidor, tente novamente."
        visible={isVisibleToast}
      />
      )}
    </Container>
  );
};

export default BabyData;
