import styled, { css } from 'styled-components';

import TimerLines from '../../assets/TimerLines.svg';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  position: fixed;
  top: 0;
  height: 100%;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const CountdownModalContent = styled.div`
  ${({ theme }) => css`
    max-width: 480px;
    background-color: ${theme.colors.background};
    margin: 0 auto;
    height: 100%;
    padding: 40px 32px;
    z-index: 10000;

    display: flex;
    flex-direction: column;
    align-items: center;

    > h1 {
      color: ${theme.colors.primaryDark50};
      letter-spacing: -1px;
      font-weight: 600;
      font-size: 2.4rem;
      line-height: 2.8rem;
      max-width: 320px;
      text-align: center;
      margin-bottom: 42px;
    }

    > small {
      letter-spacing: -0.1px;
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 1.7rem;
      color: ${theme.colors.primaryLight30};
      max-width: 246px;
      text-align: center;
      margin: 14px 0;
      margin-top: auto;
      padding-top: 42px;
      margin-bottom: 42px; 
    }

    > button {
      background-color: transparent;
      color: ${theme.icons.love};
      letter-spacing: -0.1px;
      font-weight: 600;
      font-size: 1.3rem;
      line-height: 2.2rem;
      width: 100%;
      height: 25px;
    }
  `};
`;

export const ExerciseTag = styled.div`
  ${({ theme }) => css`
    background: rgba(53, 123, 248, 0.1);
    border-radius: 6px;
    max-width: 76px;
    width: 100%;
    height: 20px;
    text-transform: uppercase;
    color: ${theme.colors.primary};
    font-weight: 500;
    font-size: 1.1rem;
    line-height: 1.5rem;
    margin-bottom: 14px;

    display: flex;
    align-items: center;
    justify-content: center;
  `};
`;

export const TimerSection = styled.div`
  ${({ theme }) => css`
    flex: 1;
    margin: auto 0;
    width: 245px;
    height: 245px;
    background: url(${TimerLines}) center center no-repeat;

    display: flex;
    align-items: center;

    .circular-chart {
      display: block;
      margin: 0 auto;
      width: 245px;
      height: 245px;
    }

    .circle-bg {
      fill: none;
      stroke: rgba(255, 255, 255, 0.5);
      stroke-width: 1;
      box-shadow: ${theme.shadows.default};
    }

    .circle {
      fill: none;
      stroke-width: 1;
      stroke-linecap: round;
      stroke: ${theme.colors.primary};

      transition: ${theme.transitions.default};

      animation: progress 5s linear forwards;

      &.finished {
        stroke: ${theme.colors.secondary};
      }
    }

    @keyframes progress {
      to {
        stroke-dashoffset: 0;
      }
    }

    .percentage {
      fill: ${theme.colors.primary};
      font-size: 8px;
      font-weight: 500;
      line-height: 6rem;
      text-anchor: middle;

      display: flex;
    }

    .percentage-finished {
      fill: ${theme.colors.secondary};
      font-size: 6px;
      font-weight: 500;
      line-height: 6rem;
      text-anchor: middle;
      dominant-baseline: middle;

      display: flex;
    }

    .percentage_small {
      fill: ${theme.colors.primaryLight30};
      font-size: 1.5px;
      font-weight: 600;
      text-anchor: middle;

      display: flex;
    }
  `};
`;

export const FinishedTexts = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    > small {
      letter-spacing: -0.1px;
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 1.7rem;
      color: ${theme.colors.secondary};
      max-width: 246px;
      text-align: center;
      margin: 14px 0;
      margin-top: auto;
      padding-top: 42px;
    }

    > button {
      margin-top: 42px;
      background-color: transparent;
      ${theme.texts.defaultSemiboldSmallTitle};
      font-weight: 600;
      color: ${theme.colors.primaryDark50};
    }
  `};
`;
