import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.neutralLight50};
    border-radius: 20px 20px 0px 0px;
    height: 100px;
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
    z-index: 100;

    display: flex;
    align-items: center;
    justify-content: center;

    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  `}
`;

export const NavbarItems = styled.ul`
  width: 100%;
  
  display: flex;
  align-items: center;
`;

export const Item = styled(Link)<{ $active?: boolean }>`
  ${({ theme, $active }) => css`
    flex: 1;
    height: 100px;
    color: rgba(113, 161, 253, 0.1);
    ${theme.texts.defaultSemiBoldText};
    cursor: not-allowed;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    
    transition: color ${theme.transitions.default};

    &:last-child, :first-child {
      color: ${theme.colors.primaryDark10};
      cursor: pointer;
    }

    ${$active && css`
      opacity: 1;
      color: ${theme.colors.primary} !important;
    `}

    > svg {
      width: 24px;
      height: 24px;
      margin-bottom: 6px;
    }
  `}
`;
