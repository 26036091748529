import React from 'react';
import {
  BrowserRouter, Route, Switch,
} from 'react-router-dom';

import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';
import WelcomeScreen from '../pages/WelcomeScreen';

// import { useWelcomed } from '../utils/welcomed';

const OtherRoutes: React.FC = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/boas-vindas" component={WelcomeScreen} />
      <Route exact path="/" component={SignIn} />
      <Route exact path="/sign-up" component={SignUp} />
    </Switch>
  </BrowserRouter>
);

export default OtherRoutes;
