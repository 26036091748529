import React, { ReactElement, useState } from 'react';
import { RiMenuLine } from 'react-icons/ri';

import {
  Container, NavbarItems, Item,
} from './styles';

import { NavbarProps } from './navbar.spec';

const Navbar = ({ navbarTabs = [], activeIndex = 0 }: NavbarProps): ReactElement => {
  const [activeTab, setActiveTab] = useState<number | any>(activeIndex);

  return (
    <Container>
      <NavbarItems>
        {navbarTabs?.map((item, index) => (
          <Item
            to={item?.to || '/'}
            $active={activeTab === index}
            key={item?.title}
            onClick={() => {
              if (!item?.disabled) {
                setActiveTab(index);
              }
            }}
          >
            {item?.icon}
            {item?.title}
          </Item>

        ))}
        <Item
          to="/menu"
          $active={activeTab === 4}
          onClick={() => {
            setActiveTab(4);
          }}
        >
          <RiMenuLine />
          Menu
        </Item>
      </NavbarItems>
    </Container>
  );
};

export default Navbar;
