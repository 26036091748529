import styled, { css } from 'styled-components';

import WaterBackground from '../../assets/WaterBg.png';
import TimerLines from '../../assets/TimerLines.svg';

interface ExerciseModalProps {
  visible?: boolean;
  finished?: boolean;
}

export const Container = styled.div<ExerciseModalProps>`
  ${({ theme, visible, finished }) => css`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 480px;
    height: 100%;
    background-color: ${theme.colors.background};
    z-index: 100;
    overflow-y: auto;
    transform: translateX(100%);
    padding-bottom: 32px;
    margin-left: auto;
    margin-right: auto;
    opacity: 0;

    transition: ${theme.transitions.default};

    &::-webkit-scrollbar {
      display: none;
    }

    ${visible
    && css`
      transform: translateX(0);
      opacity: 1;
    `}

    ${finished
    && css`
      ${Tag} {
        background-color: ${theme.colors.secondary};
      }

      ${Brand} {
        background-color: rgba(81, 212, 110, 0.1);

        > svg {
          color: ${theme.colors.secondary};
        }
      }
    `}
  `}
`;

export const ExerciseHeader = styled.div`
  ${({ theme }) => css`
    max-width: 480px;
    margin: 0 auto;
    background: ${theme.colors.white};
    height: 219px;
    background: url(${WaterBackground}) center center no-repeat;
    background-size: cover;
    padding: 32px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    > button {
      width: 40px;
      height: 40px;
      box-shadow: 0px 100px 80px rgba(53, 123, 248, 0.07),
        0px 12.5216px 10.0172px rgba(53, 123, 248, 0.035);
      border-radius: 14px;
      font-size: 1.6rem;
      color: ${theme.colors.primaryDark50};
    }
  `};
`;

export const Tag = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.softGreen};
    color: ${theme.colors.primaryDark50};
    padding: 4px 8px;
    max-width: 95px;
    border-radius: 6px;
    font-size: 1.1rem;
    line-height: 1.5rem;
    font-weight: 500;
    text-transform: uppercase;
    height: 21px;

    display: flex;
    align-items: center;
    justify-content: center;
  `};
`;

export const ExercisePostHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    margin-bottom: 20px;

    > h1 {
      color: ${theme.colors.primaryDark50};
      letter-spacing: -1px;
      font-weight: 600;
      font-size: 2.4rem;
      line-height: 2.8rem;
      max-width: 250px;
    }
  `};
`;

export const Brand = styled.div`
  ${({ theme }) => css`
    width: 56px;
    height: 56px;
    border-radius: 10px;
    background-color: rgba(133, 240, 157, 0.1);
    margin-right: 16px;

    display: flex;
    justify-content: center;
    align-items: center;

    > svg {
      color: ${theme.colors.softGreen};
      font-size: 2.6rem;
    }
  `};
`;

export const Progress = styled.div`
  ${({ theme }) => css`
    margin: 0 0 20px 0;

    > small {
      display: block;
      margin-bottom: 20px;
      color: ${theme.colors.primaryDark50};
      font-size: 1.1rem;
      line-height: 1.5rem;
    }
  `};
`;

export const MeditationCard = styled.div`
  height: 84px;
  padding: 12px 12px 12px 24px;
  background-color: #EAF6F2;
  border-radius: 8px;

  display: flex;
  align-items: center;

  > img {
    margin-left: auto;
  }
`;

export const ProgressText = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    margin-left: 16px;
    font-size: 1.1rem;
    line-height: 1.5rem;
    font-weight: 400;
    color: ${theme.colors.primaryDark50};

    > b {
      letter-spacing: -0.5px;
      font-weight: 600;
      font-size: 1.8rem;
      line-height: 2rem;
    }
  `};
`;

export const ProgressButton = styled.div`
  ${({ theme }) => css`
    width: 50px;
    height: 50px;
    border: 4px solid rgba(53, 123, 248, 0.1);
    background-color: ${theme.colors.white};
    border-radius: 50%;
    font-weight: 600;
    font-size: 1.3rem;
    line-height: 1.5rem;
    color: ${theme.colors.primary};
    box-shadow: ${theme.shadows.default};

    display: flex;
    justify-content: center;
    align-items: center;
  `};
`;

export const SocialShare = styled.div`
  ${({ theme }) => css`
    margin-top: 20px;
    text-align: center;

    display: flex;
    flex-direction: column;
    align-items: center;

    > h2 {
      color: ${theme.colors.primaryDark50};
      letter-spacing: -0.5px;
      font-weight: 600;
      font-size: 1.8rem;
      line-height: 2rem;
    }

    > p {
      margin-top: 8px;
      max-width: 320px;
      color: ${theme.colors.primaryDark50};
      font-size: 1.1rem;
      line-height: 1.5rem;
      font-weight: 400;
    }
  `};
`;

export const ExerciseContent = styled.div`
  ${({ theme }) => css`
    padding: 28px 32px 0 32px;

    > small {
      font-weight: 400;
      font-size: 11px;
      line-height: 15px;
      color: ${theme.colors.primaryDark50};
    }

    > button {
      background-color: ${theme.colors.primary};
      border: 1px solid ${theme.colors.primary};
      width: 100%;
      height: 50px;
      border-radius: 10px;
      margin: 28px 0;
      padding: 0 22px;
      font-weight: 600;
      font-size: 1.8rem;
      line-height: 2rem;
      letter-spacing: -0.5px;
      color: ${theme.colors.neutralLight50};

      display: flex;
      justify-content: space-between;

      transition: ${theme.transitions.default};

      &:hover {
        background-color: transparent;
        color: ${theme.colors.primary};
      }

      > span {
        display: flex;
        align-items: center;

        > svg {
          margin-right: 8px;
        }
      }
    }
  `};
`;

export const Divider = styled.div`
  display: flex;
  justify-content: center;

  margin: 24px 0;

  &::before,
  &::after {
    content: '';
    flex: 1 1;
    border-bottom: 1px solid rgba(53, 123, 248, 0.1);
    margin: auto;
  }

  &::before {
    margin-right: 20px;
  }

  &::after {
    margin-left: 20px;
  }
`;

export const SectionHeader = styled.div`
  ${({ theme }) => css`
    padding: 0 32px 32px 32px;
    color: ${theme.colors.primaryDark50};
    font-weight: 400;
    font-size: 1.1rem;
    line-height: 1.5rem;

    display: flex;
    justify-content: space-between;

    > span {
      display: flex;
      align-items: center;

      > svg {
        font-size: 1.3rem;
      }
    }
  `};
`;

export const OtherExerciseCard = styled.div`
  ${({ theme }) => css`
    box-shadow: ${theme.shadows.default};
    background-color: ${theme.colors.neutralLight50};
    border-radius: 16px;
    padding: 24px;
    margin: 0 32px;

    display: flex;
    flex-direction: column;

    > img {
      max-width: 145px;
      margin: 0 auto;
    }
  `};
`;

export const OtherExerciseCardHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    > span {
      color: ${theme.colors.neutralLight50};
      background-color: ${theme.colors.pink};
      border-radius: 14px;
      width: 34px;
      height: 34px;

      display: flex;
      align-items: center;
      justify-content: center;

      > svg {
        font-size: 1.6rem;
      }
    }
  `};
`;

export const OtherExerciseTexts = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.primaryDark50};
    margin-top: 24px;

    > h3 {
      letter-spacing: -0.5px;
      font-size: 1.8rem;
      line-height: 2rem;
      font-weight: 600;
      max-width: 278px;
      margin-bottom: 8px;
    }

    > p {
      font-size: 1.1rem;
      font-weight: 400;
      line-height: 1.5rem;
      max-width: 278px;
    }

    > button {
      margin-top: 16px;
      border-radius: 10px;
      background-color: ${theme.colors.pink};
      height: 46px;
      width: 100%;
      color: ${theme.colors.neutralLight50};
      letter-spacing: -0.1px;
      font-size: 1.4rem;
      line-height: 1.6rem;
      font-weight: 500;

      > svg {
        margin-left: 6px;
      }
    }
  `};
`;

export const OtherExerciseTag = styled.div`
  ${({ theme }) => css`
    border-radius: 6px;
    height: 23px;
    background-color: ${theme.colors.pink};
    text-transform: uppercase;
    padding: 4px 8px;
    color: ${theme.colors.neutralLight50};
    font-weight: 500;
    font-size: 1.1rem;
    line-height: 1.5rem;
  `};
`;

export const CountdownModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 103vh;
  
`;

export const CountdownModalContent = styled.div`
  ${({ theme }) => css`
    max-width: 480px;
    background-color: ${theme.colors.background};
    margin: 0 auto;
    /* flex: 1; */
    /* height: 100%; */
    padding: 40px 32px;
    z-index: 10000;
    height: -webkit-fill-available;

    display: flex;
    flex-direction: column;
    align-items: center;

    > h1 {
      color: ${theme.colors.primaryDark50};
      letter-spacing: -1px;
      font-weight: 600;
      font-size: 2.4rem;
      line-height: 2.8rem;
      max-width: 320px;
      text-align: center;
      margin-bottom: 42px;
    }

    > small {
      letter-spacing: -0.1px;
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 1.7rem;
      color: ${theme.colors.primaryLight30};
      max-width: 246px;
      text-align: center;
      margin: 14px 0;
      margin-top: auto;
      padding-top: 42px;
      margin-bottom: 42px; 
    }

    > button {
      background-color: transparent;
      color: ${theme.icons.love};
      letter-spacing: -0.1px;
      font-weight: 600;
      font-size: 1.3rem;
      line-height: 2.2rem;
      width: 100%;
      height: 25px;
    }
  `};
`;

export const ExerciseTag = styled.div`
  ${({ theme }) => css`
    background: rgba(53, 123, 248, 0.1);
    border-radius: 6px;
    max-width: 76px;
    width: 100%;
    height: 20px;
    text-transform: uppercase;
    color: ${theme.colors.primary};
    font-weight: 500;
    font-size: 1.1rem;
    line-height: 1.5rem;
    margin-bottom: 14px;

    display: flex;
    align-items: center;
    justify-content: center;
  `};
`;

export const TimerSection = styled.div`
  ${({ theme }) => css`
    flex: 1;
    margin: auto 0;
    width: 245px;
    height: 245px;
    background: url(${TimerLines}) center center no-repeat;

    display: flex;
    align-items: center;

    .circular-chart {
      display: block;
      margin: 0 auto;
      width: 245px;
      height: 245px;
    }

    .circle-bg {
      fill: none;
      stroke: rgba(255, 255, 255, 0.5);
      stroke-width: 1;
      box-shadow: ${theme.shadows.default};
    }

    .circle {
      fill: none;
      stroke-width: 1;
      stroke-linecap: round;
      stroke: ${theme.colors.primary};

      transition: ${theme.transitions.default};

      animation: progress 5s linear forwards;
    }

    @keyframes progress {
      to {
        stroke-dashoffset: 0;
      }
    }

    .percentage {
      fill: ${theme.colors.primary};
      font-size: 8px;
      font-weight: 500;
      line-height: 6rem;
      text-anchor: middle;

      display: flex;
    }

    .percentage_small {
      fill: ${theme.colors.primaryLight30};
      font-size: 1.5px;
      font-weight: 600;
      text-anchor: middle;

      display: flex;
    }
  `};
`;

export const MainQuantityInput = styled.div`
  ${({ theme }) => css`
    display: flex;
    margin-bottom: 14px;

    > button {
      border-radius: 8px;
      background-color: ${theme.colors.softGreen};
      color: ${theme.colors.primaryDark50};
      ${theme.texts.defaultBoldButton};
      height: 44px;
      padding: 0 22px;

      transition: ${theme.transitions.default};

      &:hover {
        opacity: 0.8;
      }

      &:disabled {
        opacity: 0.8;
      }
    }
  `};
`;

export const InputQuantity = styled.div`
  ${({ theme }) => css`
    position: relative;
    margin-right: 12px;
    flex: 1;

    > input {
      border-radius: 8px;
      ${theme.texts.defaultRegularBody};
      color: ${theme.colors.primaryDark50};
      background-color: transparent;
      border: 1px solid ${theme.colors.softGreen};
      height: 44px;
      flex: 1;
      width: 100%;
      padding: 0 30px 0 14px;
    }
    
    > span {
      position: absolute;
      right: 14px;
      top: 50%;
      transform: translateY(-50%);
      color: ${theme.colors.primaryDark50};
      ${theme.texts.defaultRegularBody};
    }
  `};
`;

export const QuantitiesButton = styled.div`
  ${({ theme }) => css`
    display: flex;  

    > button {
      border-radius: 8px;
      ${theme.texts.defaultRegularBody};
      color: ${theme.colors.primaryDark50};
      background-color: transparent;
      border: 1px solid ${theme.colors.softGreen};
      height: 44px;
      flex: 1;

      transition: ${theme.transitions.default};

      &:hover {
        opacity: 0.8;
      }

      :not(:last-child) {
        margin-right: 10px;
      }
    }
  `};
`;

export const QuantityControl = styled.div`
  margin: 20px 0;

  display: flex;
  flex-direction: column;
`;

export const CompletedItem = styled.div`
  ${({ theme }) => css`
    height: 50px;
    border-radius: 10px;
    background-color: ${theme.colors.secondary};
    margin-bottom: 20px;
    padding: 15px 22px;
    color: ${theme.colors.white};
    ${theme.texts.defaultSemiBoldTitle};
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: space-between;
  `};
`;

export const RoundedPercentage = styled.div`
  ${({ theme }) => css`
    width: 50px;
    height: 50px;
    border: 0.5px solid rgba(236, 241, 254, 1);
    border-radius: 50%;

    .circular-chart {
      display: block;
      margin: 0 auto;
      max-height: 250px;
    }
    .circle-bg {
      fill: none;
      /* stroke: rgba(53, 123, 248, 0.1); */
      stroke-width: 2;
    }
    .circle {
      fill: ${theme.colors.white};
      stroke-width: 2;
      stroke-linecap: round;
      stroke: #85F09D;
      animation: progress 1s ease-out forwards;
    }
    @keyframes progress {
      0% {
        stroke-dasharray: 0 100;
      }
    }
    .percentage {
      fill: ${theme.colors.primaryDark50};
      font-size: 0.7rem;
      font-weight: bold;
      text-anchor: middle;
      display: flex;
    }
    .percentage_small {
      fill: ${theme.colors.primaryDark50};
      opacity: 0.6;
      font-size: 0.22em;
      font-weight: bold;
      text-anchor: middle;
      display: flex;
    }
  `}
`;
