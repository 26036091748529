import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    margin: 36px 0 40px 0;

    > h1 {
      color: ${theme.colors.primaryDark50};
      ${theme.texts.defaultSemiBoldHeadline};
      margin-bottom: 20px;
    }
  `}
`;

export const InputControl = styled.div`
  ${({ theme }) => css`
    margin-bottom: 20px;

    > small {
      color: ${theme.colors.primaryDark50};
      ${theme.texts.defaultRegularBody};
      margin-bottom: 14px;

      display: flex;
    }

    .cpfInputMask {
      width: 100%;
      border: 1px solid rgba(53, 60, 72, 0.1);
      border-radius: 10px;
      padding: 14px 22px;
      max-height: 50px;
      background-color: transparent;

      ${theme.texts.defaultRegularOption};
      transition: ${theme.transitions.default};

      &::placeholder {
        color: ${theme.colors.primaryDark50};
        opacity: 0.2;
        transition: ${theme.transitions.default};
      }

      &:focus {
        color: ${theme.colors.primaryDark50};
        border-color: ${theme.colors.primaryDark50};

        &::placeholder {
          opacity: 1;
        }
      }

      &:disabled {
        color: ${theme.colors.neutralLight50};
        cursor: not-allowed;

        &::placeholder {
          color: ${theme.colors.neutralLight50};
          opacity: 1;
        }
      }
    }
  `}
`;

export const PrivacyWarning = styled.div`
  ${({ theme }) => css`
    ${theme.texts.defaultSemiBoldSmallTitle};
    text-align: center;
    max-width: 326px;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 39px;
    color: ${theme.colors.primaryDark50};

    > b {
      margin: 0 4px;
      color: ${theme.colors.primary};
    }
  `};
`;

export const BackButton = styled.div`
  ${({ theme }) => css`
    margin-bottom: 20px;
    cursor: pointer;

    display: flex;
    align-items: center;

    > a {
      color: ${theme.colors.primaryDark50};
      ${theme.texts.defaultRegularBody};

      display: flex;
      align-items: center;
    
      > svg {
        font-size: 17px;
      }
    }
  `};
`;

export const SignUpForm = styled.form``;
