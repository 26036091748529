/* eslint-disable */
import api from './api';
import moment from 'moment';

export default {
  async post(customerData: any) {
    try {
      return await api.post('customers/register', {
        ...customerData,
      });
    } catch {
      throw new Error("Can't create a customer");
    }
  },

  async update(id: number, customerData: any) {
    try {
      return await api.put(`customers/${id}`, {
        data: {
          ...customerData,
        },
      });
    } catch {
      console.log("Can't update the selected customer");
      return {
        data: [],
      };
    }
  },

  async getOne(id: number) {
    try {
      return await api.get(`customers/${id}`);
    } catch {
      console.log("Can't get the selected customer");
      return {
        data: [],
      };
    }
  },
  async getMe() {
    const todayDate = new Date();
    
    const startDate = new Date(`${moment(todayDate).format('YYYY-MM-DD')}T00:00:00.000`).toISOString();
    const finalDate = new Date(`${moment(todayDate).format('YYYY-MM-DD')}T23:59:59.000`).toISOString();
    
    console.log(startDate, finalDate);

    try {
      return (
        await api.get(
          `users/me?populate[customer][populate][challenges][populate][challengeType]=*&populate[customer][populate][children]=*&populate[customer][populate][challenges][filters][$and][0][createdAt][$gte]=${startDate}&populate[customer][populate][challenges][filters][$and][1][createdAt][$lte]=${finalDate}`,
        )
      ).data;
    } catch {
      console.log("Can't get the me informations");
      return {
        data: [],
      };
    }
  },

  async getChallenges(customerId: number) {
    try {
      return (
        await api.get(
          `customers/${customerId}?populate[challenges][filters][status][$eq]=completed&populate[challenges][sort]=createdAt:desc&populate[challenges][populate][challengeType]=*`,
        )
      ).data;
    } catch {
      console.log("Can't get the me informations");
      return {
        data: [],
      };
    }
  },

  async getChildren(customerId: number) {
    try {
      return (await api.get(`customers/${customerId}?populate=children`)).data;
    } catch {
      console.log("Can't get the me informations");
      return {
        data: [],
      };
    }
  },
};
