import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RiEyeLine, RiEyeOffLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';

import { setTimeout } from 'timers';
import { useAuth } from '../../contexts/AuthContext';

import Button from '../../components/Button';
import Input from '../../components/Input';
import Toast from '../../components/Toast';

import {
  Container,
  MainContent,
  Form,
  InputControl,
  RegisterToast,
  // SocialLogin,
  // SocialButtons,
  ImageBackground,
} from './styles';

const SignIn: React.FC = () => {
  const storagedUser = localStorage.getItem('@App:user');
  const { Login } = useAuth();
  const { t: translate } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isHiddenText, setIsHiddenText] = useState<boolean>(true);
  const [isVisibleToast, setIsVisibleToast] = useState<boolean>(false);

  async function handleLogin(event: any): Promise<void> {
    event.preventDefault();
    setIsLoading(true);

    const { email, password } = event.target.elements;

    Login({
      identifier: email?.value,
      password: password?.value,
    }).catch(() => {
      setIsVisibleToast(true);

      setTimeout(() => {
        setIsVisibleToast(false);
      }, 2500);

      setIsLoading(false);
    });
  }

  function handleRevealText(): void {
    setIsHiddenText(!isHiddenText);
  }

  if (!storagedUser) {
    return (
      <Container>
        <MainContent>
          <h1>{translate('accountSignIn')}</h1>

          <Form onSubmit={(e) => handleLogin(e)}>
            <InputControl>
              <span>Seu e-mail</span>
              <Input
                type="email"
                placeholder="Seu e-mail"
                required
                name="email"
              />
            </InputControl>

            <InputControl>
              <span>
                {translate('yourPassword')}
                <small>{translate('forgotPassword')}</small>
              </span>
              <Input
                type={isHiddenText ? 'password' : 'text'}
                placeholder="****************"
                icon={isHiddenText ? <RiEyeLine /> : <RiEyeOffLine />}
                state="default"
                handleRevealText={() => handleRevealText()}
                required
                name="password"
              />
            </InputControl>

            <Button message={translate('signIn')} isLoading={isLoading} />
          </Form>

          {/* <SocialLogin>
          <small>{translate('signInAlternative')}</small>

          <SocialButtons>
            <button type="button" disabled>
              <img src="/assets/Google.svg" alt="Google" />
            </button>
            <button type="button" disabled>
              <img src="/assets/Facebook.svg" alt="Facebook" />
            </button>
          </SocialButtons>
        </SocialLogin> */}
        </MainContent>

        <RegisterToast>
          {translate('noHasAccount')}
          <Link to="/sign-up">{translate('register')}</Link>
        </RegisterToast>

        <ImageBackground>
          <img src="/assets/SignInBackground.png" alt="Background" />
        </ImageBackground>

        <Toast
          type="error"
          message="Credenciais inválidas, tente novamente."
          visible={isVisibleToast}
        />
      </Container>
    );
  }
  return <></>;
};

export default SignIn;
