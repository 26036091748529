import React, { useState } from 'react';
import {
  RiCheckLine,
  RiArrowRightLine,
  // RiFileCopyLine
} from 'react-icons/ri';
import { useHistory } from 'react-router-dom';
import Button from '../../../components/Button';

import childrenService from '../../../services/children';

import RegisterElements from '../../../assets/RegisterElements.png';

import {
  Container,
  NestedContainer,
  BrandCheck,
  MainTexts,
  SupportNetworkCode,
  CopyInput,
  BottomButtons,
} from './styles';
import Toast from '../../../components/Toast';

const SupportNetworkFather = (): any => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [verifyCode, setVerifyCode] = useState<any>(null);
  const routerHistory = useHistory() as any;
  const [isVisibleToast, setIsVisibleToast] = useState<boolean>(false);

  async function attachChildren(): Promise<any> {
    setIsLoading(true);

    setTimeout(async () => {
      try {
        await childrenService.attach(verifyCode);

        routerHistory.push('/');
        window.location.reload();
      } catch {
        setIsVisibleToast(true);
        setTimeout(() => {
          setIsVisibleToast(false);
        }, 2500);
        // console.log('Error to attach a father to the children.');
      }

      setIsLoading(false);
    }, 2000);
  }

  return (
    <NestedContainer>
      <img src={RegisterElements} alt="RegisterElements" />

      <Container>
        <BrandCheck>
          <RiCheckLine />
        </BrandCheck>

        <MainTexts>
          <h1>Cadastro realizado!</h1>
          <p>
            Dados cadastrados com sucesso! Entre agora mesmo com sua rede de
            apoio para os Mil Dias.
          </p>
        </MainTexts>

        <SupportNetworkCode>
          <small>Informar o código:</small>
          <CopyInput>
            <input
              type="text"
              placeholder="6 dígitos"
              value={verifyCode || ''}
              onChange={(e) => {
                setVerifyCode(e?.target.value);
              }}
              maxLength={6}
            />
          </CopyInput>
        </SupportNetworkCode>

        <small>
          Este código é gerado pela
          {' '}
          <b>Mãe do bebê</b>
          {' '}
          cadastrada do app,
          solicite-o e preencha acima.
        </small>

        <BottomButtons>
          <button
            type="button"
            onClick={() => {
              routerHistory.push('/');
              window.location.reload();
            }}
          >
            Ir para a Home
            <RiArrowRightLine />
          </button>
          <Button
            type="button"
            message="Concluir"
            isLoading={isLoading}
            onClick={() => attachChildren()}
            disabled={!verifyCode}
          />
        </BottomButtons>
      </Container>

      <Toast
        type="success"
        message="Código copiado para área de transferência."
        visible={isVisibleToast}
      />
    </NestedContainer>
  );
};
export default SupportNetworkFather;
