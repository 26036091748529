import React from 'react';
import { ThemeProvider } from 'styled-components';
// import {
//   RiHomeSmile2Line,
//   RiUserSmileLine,
//   RiOpenArmLine,
//   RiTeamLine,
//   RiMenuLine,
// } from 'react-icons/ri';
// import Navbar from './components/Navbar';

import { AuthProvider } from './contexts/AuthContext';
import Routes from './routes';

import GlobalStyles from './styles/themes/globalStyles';
import defaultTheme from './styles/themes/defaultTheme';
import './styles/fonts/index.css';

// const navbarTabs = [
//   { icon: <RiHomeSmile2Line />, title: 'Hoje' },
//   { icon: <RiOpenArmLine />, title: 'Bebê' },
//   { icon: <RiUserSmileLine />, title: 'Eu' },
//   { icon: <RiTeamLine />, title: 'Grupos' },
//   { icon: <RiMenuLine />, title: 'Menu' },
// ];

const App: React.FC = () => (
  <ThemeProvider theme={defaultTheme}>
    <AuthProvider>
      <Routes />
      <GlobalStyles />
    </AuthProvider>
  </ThemeProvider>
);

export default App;
