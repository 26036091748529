import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { RiCheckLine, RiArrowRightLine, RiFileCopyLine } from 'react-icons/ri';

import RegisterElements from '../../../assets/RegisterElements.png';

import Toast from '../../../components/Toast';

import {
  Container, NestedContainer, BrandCheck, MainTexts, SupportNetworkCode, CopyInput,
} from './styles';

const SupportNetwork = (): any => {
  const babyCode = localStorage.getItem('@App:babyCode') as any;
  const routerHistory = useHistory() as any;
  const [isVisibleToast, setIsVisibleToast] = useState<boolean>(false);

  function handleCopyClipboard(toCopy: string): any {
    setIsVisibleToast(true);
    navigator.clipboard.writeText(toCopy);

    setTimeout(() => {
      setIsVisibleToast(false);
    }, 2500);
  }

  return (
    <>
      <NestedContainer>
        <img src={RegisterElements} alt="RegisterElements" />

        <Container>
          <BrandCheck>
            <RiCheckLine />
          </BrandCheck>

          <MainTexts>
            <h1>Cadastro realizado!</h1>
            <p>
              Dados cadastrados com sucesso! Entre agora mesmo com sua rede de apoio
              para os Mil Dias.
            </p>
          </MainTexts>

          <SupportNetworkCode>
            <small>Código para sua rede de apoio:</small>
            <CopyInput>
              <input type="text" value={babyCode || ''} disabled />
              <RiFileCopyLine onClick={() => handleCopyClipboard(babyCode)} />
            </CopyInput>
          </SupportNetworkCode>

          <small>
            Este código é gerado para que sua rede de apoio compartilhe informações
            sobre sua gestação.
          </small>

          <button
            type="button"
            onClick={() => {
              routerHistory.push('/');
              window.location.reload();
            }}
          >
            Ir para a Home
            <RiArrowRightLine />
          </button>
        </Container>
      </NestedContainer>

      <Toast
        type="success"
        message="Código copiado para área de transferência."
        visible={isVisibleToast}
      />
    </>
  );
};
export default SupportNetwork;
