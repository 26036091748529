import styled, { css } from 'styled-components';

interface ContainerProps {
  type?: 'error' | 'success' | 'none';
  visible: boolean;
}

export const Container = styled.div<ContainerProps>`
  ${({ theme, type, visible }) => css`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
    z-index: 999999;
    transform: translateY(-100%);

    transition: ${theme.transitions.default};

    ${type === 'success'
    && css`
      ${ToastContent} {
        background-color: ${theme.toasts.success};
      }
    `};

    ${type === 'error'
    && css`
      ${ToastContent} {
        background-color: ${theme.toasts.error};
      }
    `};

    ${visible
    && css`
      transform: translateY(14px);
    `};
  `};
`;

export const ToastContent = styled.div`
  ${({ theme }) => css`
    margin: 0 32px;
    background-color: ${theme.colors.secondary};
    height: 50px;
    border-radius: 10px;
    padding: 0 22px;

    display: flex;
    align-items: center;

    > p {
      color: ${theme.colors.neutralLight50};
      font-size: 1.3rem;
      font-weight: 600;
    }
  `};
`;
