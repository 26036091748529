export const TRANSLATIONS_PT_BR = {
  signIn: 'Entrar',
  today: 'Hoje',
  marketplace: 'Marketplace',
  teleconsultation: 'Teleconsulta',
  back: 'Voltar',
  accountSignIn: 'Entre em sua conta',
  yourDocument: 'Seu CPF',
  typeYourDocument: 'Digite seu CPF',
  yourPassword: 'Sua senha',
  forgotPassword: 'Esqueceu sua senha?',
  signInAlternative: 'Ou entre com:',
  noHasAccount: 'Ainda não possui uma conta?',
  register: 'Cadastre-se',
  welcome: 'Bem-vindo(a)!',
  welcomeCarousel: {
    challenge: {
      title: 'Um desafio saudável para toda família!',
      description:
        'São 1.000 dias para você ter todo cuidado com seu bebê, construindo uma jornada linda e cheia de interatividade.',
    },
    professionals: {
      title: 'Profissionais prontos para lhe atender!',
      description:
        'Teleconsulta que guia você a hora que você precisar! Sim, profissionais disponíveis 24h por dia.',
    },
    network: {
      title: 'Uma rede familiar onde todo mundo se diverte!',
      description:
        'Um ecossistema de colaboração familiar para todos: pais, tios, avós, primos e quem desejar fazer parte do desafio.',
    },
    partners: {
      title: 'Parceiros incríveis para sua jornada ser perfeita!',
      description:
        'Através do nosso marketplace, você pode adquirir produtos personalizados com descontos exclusivos.',
    },
  },
  skipStep: 'Pular esta etapa',
  tryingToGetPregnant: 'Está tentando engravidar?',
  signTrying: 'Entrar como tentante',
};
