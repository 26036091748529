import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }
  
  html {
    font-size: 62.5%; // 1rem = 10px
    height: 100%;
    font-family: 'SF Pro Text', 'Inter', Helvetica, Arial, sans-serif;
    background: #F5F7FB;
    -webkit-tap-highlight-color: transparent;
    overflow-x: hidden;

    ::-webkit-scrollbar {
      display: none;
    }
  }

  button, input {
    font-family: 'SF Pro Text', 'Inter', Helvetica, Arial, sans-serif;
    font-size: 1.4rem;
  }

  body {
    height: auto;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale;
    max-width: min(480px, 100%);
    margin: 0 auto;

    &.no-scroll {
      overflow: hidden;
    }

    ::-webkit-scrollbar {
      display: none;
    }
  }

  body, #root {
    min-height: 100vh;
    position: relative;
    
    display: flex;
    flex-direction: column;
  }

  #root {
    height: 100%;
  }

  input, div, li, button, a, textarea, select {
    -webkit-tap-highlight-color: transparent; 
  }

  a {
    text-decoration: none;
    background: none;
    cursor: pointer;
    border: 0;
  }

  button {
    cursor: pointer;
    border: 0;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  ul {
    list-style: none;
    padding: 0;
    text-align: left;
  }

  .react-date-picker__wrapper {
    border: 1px solid rgba(53, 60, 72, 0.1);
    height: 50px;
    border-radius: 10px;
    padding: 0 22px;
  }

  .react-date-picker__inputGroup__input:invalid {
    background-color: transparent;
  }

  .react-date-picker__calendar {
    width: 100%;
  }

  .react-calendar {
    width: 100%;
    border-color: transparent;
    box-shadow: 0px 100px 80px rgba(53, 123, 248, 0.07), 0px 12.5216px 10.0172px rgba(53, 123, 248, 0.035);
    border-radius: 0 0 14px 14px;
    padding: 30px;
  }

  .react-calendar__navigation button {
    color: #357BF8;
    font-size: 16px;
    
    &:hover, &:focus, &:active {
      background-color: transparent !important;
    }
  }

  .react-calendar__navigation__label__labelText {
    letter-spacing: -0.5px;
    color: #353C48;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  }

  abbr[title] {
    border-bottom: none !important;
    cursor: inherit !important;
    text-decoration: none !important;
    text-transform: uppercase !important;
  }

  abbr {
    text-transform: capitalize !important;
  }

  .react-calendar__month-view__weekdays__weekday {
    color: #CFCFCF;
    font-weight: 600;
  }

  .react-calendar__month-view__days__day--weekend {
    color: #353C48;
  }

  .react-calendar__tile {
    height: 46px;
    border-radius: 10px;
  }

  .react-calendar__tile--active {
    color: #fff;
  }

  .react-calendar__tile.react-calendar__tile--now {
    background: rgba(53, 123, 248, 0.1);
    color: #353C48;

    &:hover {
      background-color: rgba(53, 123, 248, 0.1);
    }
  }
`;

export default GlobalStyle;
