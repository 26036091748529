import styled, { css, keyframes } from 'styled-components';
import defaultTheme from '../../styles/themes/defaultTheme';

interface CustomInputProps {
  state?: 'success' | 'alert' | 'error' | 'default';
  isFocused?: boolean;
}

export const Container = styled.div``;

export const LoadingCircle = styled.div`
  ${({ theme }) => css`
    position: absolute;
    right: 22px;
    top: 25%;

    padding: 1.2px;
    border-radius: 50%;
    display: inline-block;
    background: conic-gradient(
      from 180deg at 50% 50%,
      #353C48 0deg,
      rgba(255, 255, 255, 0) 360deg
    );

    transition: ${theme.transitions.default};
    animation: ${spinAnimation} 0.6s linear infinite;
  `}
`;

export const Circle = styled.div`
  ${({ theme }) => css`
    width: 12px;
    height: 12px;
    background-color: ${theme.colors.background};
    border-radius: 50%;
    transition: ${theme.transitions.default};
  `}
`;

const spinAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(-360deg); }
`;

const iconStateStyles = (state = 'default'): any => ({
  error: css`
    > svg {
      color: #FD5E5A;
    }
  `,
  alert: css`
    > svg {
      color: #FFD30E;
    }
  `,
  success: css`
    > svg {
      color: #51D46E;
    }
  `,
  default: css`
    > svg {
      color: #357BF8;
    }
  `,
}[state]);

const inputStateStyles = (state = 'default'): any => ({
  error: css`
    color: ${defaultTheme.icons.love};
    border-color: ${defaultTheme.icons.love};

    &::placeholder {
      color: ${defaultTheme.icons.love};
      opacity: 1;
    }

    &:focus {
      border-color: ${defaultTheme.colors.primaryDark50};

      &::placeholder {
        color: ${defaultTheme.colors.primaryDark50};
      }
    }
  `,
  alert: css`
    color: ${defaultTheme.icons.rating};
    border-color: ${defaultTheme.icons.rating};

    &::placeholder {
      color: ${defaultTheme.icons.rating};
      opacity: 1;
    }

    &:focus {
      &::placeholder {
        color: ${defaultTheme.colors.primaryDark50};
      }
    }
  `,
  success: css`
    color: ${defaultTheme.colors.secondary};
    border-color: ${defaultTheme.colors.secondary};

    &::placeholder {
      color: ${defaultTheme.colors.secondary};
      opacity: 1;
    }

    &:focus {
      &::placeholder {
        color: ${defaultTheme.colors.primaryDark50};
      }
    }
  `,
  default: css`
    color: ${defaultTheme.colors.primaryDark50};
    border-color: rgba(53, 60, 72, 0.1);
  `,
}[state]);

export const InputContainer = styled.div<CustomInputProps>`
  ${({ state, isFocused }) => css`
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    ${CustomInput} {
      ${inputStateStyles(state)};
    }

    ${!isFocused && `
      ${Icon} {
        ${iconStateStyles(state)};
      }
    `}
  `}
`;

export const CustomInput = styled.input`
  ${({ theme }) => css`
    width: 100%;
    border: 1px solid rgba(53, 60, 72, 0.1);
    border-radius: 10px;
    padding: 14px 22px;
    max-height: 50px;
    background-color: transparent;

    ${theme.texts.defaultRegularOption};
    transition: ${theme.transitions.default};

    &::placeholder {
      color: ${theme.colors.primaryDark50};
      opacity: 0.2;
      transition: ${theme.transitions.default};
    }

    &:focus {
      color: ${theme.colors.primaryDark50};
      border-color: ${theme.colors.primaryDark50};

      &::placeholder {
        opacity: 1;
      }
    }

    &:disabled {
      color: ${theme.colors.neutralLight50};
      cursor: not-allowed;

      &::placeholder {
        color: ${theme.colors.neutralLight50};
        opacity: 1;
      }
    }
  `}
`;

export const Icon = styled.div`
  ${({ theme }) => css` 
    position: absolute;
    right: 22px;
    top: 17.5px;

    > svg {
      width: 13px;
      height: 13px;
      cursor: pointer;

      transition: ${theme.transitions.default};
    }
  `}
`;
