import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './locales/i18n';

import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <App />,
  document.getElementById('root'),
);

reportWebVitals();
