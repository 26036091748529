import styled, { css, keyframes } from 'styled-components';
import defaultTheme from '../../styles/themes/defaultTheme';

export const Container = styled.div`
  ${({ theme }) => css`
    padding: 6.5px 8px;
    background-color: ${theme.colors.neutralLight50};
    border-radius: 100px;
    box-shadow: ${theme.shadows.default};
    max-width: 326px;
    margin: 0 auto;
    margin-top: 22px;
  `}
`;

export const Tabs = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.neutralLight50};
    display: flex;
  `}
`;

export const TabItem = styled.div<{ active?: boolean }>`
  ${({ theme, active }) => css`
    padding: 10px 14px;
    max-height: 36px;
    background-color: transparent;
    border-radius: 100px;
    color: ${theme.colors.primaryDark50};
    cursor: pointer;
    position: relative;
    transition: ${theme.transitions.default};

    ${theme.texts.defaultMediumMediumTitle};

    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
      display: none;
      margin-right: 6px;
    }

    ${active && css`
      animation: ${switchAnimation} 0.8s linear forwards;

      > svg, span {
        display: block;
      }
    `}

    @media (max-width: 380px) {
      > svg {
        display: block;
      }

      > span {
        display: none;
      }

      ${active && css`
        > span {
          display: block;
        }
      `}
    }
  `}
`;

const switchAnimation = keyframes`
  0% { 
    width: 20%;
    color: transparent;
  }

  50% {
    background: ${defaultTheme.colors.primary};
    color: ${defaultTheme.colors.neutralLight50};
  }

  100% { 
    background: ${defaultTheme.colors.primary};
    color: ${defaultTheme.colors.neutralLight50};
    width: 100%;
  }
`;
