/* eslint-disable */
import React, { useState, useMemo, useEffect, ReactElement } from 'react';
import moment from 'moment';

import {
  RiInformationLine,
  RiDossierLine,
  RiCalendarLine,
  RiStore2Line,
  RiHomeSmile2Line,
  RiUserSmileLine,
  RiOpenArmLine,
  RiArrowRightSLine,
  RiTeamLine,
  RiCheckFill,
} from 'react-icons/ri';

import { getMonthName, dayOfWeek } from '../../utils/calendarFormatters';

import Star from '../../assets/Star.svg';
import Face from '../../assets/Face.svg';
import MeditationIllustration from '../../assets/MeditationIllustration.svg';
import ExerciseIllustration from '../../assets/ExerciseIllustration.svg';
import SleepIllustration from '../../assets/SleepIllustration.svg';
import ExerciseIllustrationRed from '../../assets/ExerciseIllustrationRed.svg';
import FoodIllustration from '../../assets/FoodIllustration.svg';
import MeditationSmallIlustration from '../../assets/MeditationSmallIlustration.png';
import MovimentationIllustration from '../../assets/MovimentationIllustration.svg';
import MeasureIllustration from '../../assets/MeasureIllustration.svg';
import LargeMeditationIllustration from '../../assets/LargeMeditationIllustration.svg';

import PregnantWallet from '../../components/PregnantWallet';
import SectionSwitch from '../../components/SectionSwitch';
import Navbar from '../../components/Navbar';
import ExerciseModal from '../../components/MeditationModal';
import GeneralExerciseModal from '../../components/GeneralExerciseModal';
import SleepModal from '../../components/SleepModal';
import MeasureModal from '../../components/MeasureModal';

import customerService from '../../services/customer';
import challengesService from '../../services/challenges';

import {
  Container,
  UserHeader,
  UserHeaderTop,
  UserHeaderLeftContent,
  UserImage,
  PointsControl,
  PointsProgress,
  ProgressBar,
  PointsTexts,
  ChallengesSection,
  ChallengeSectionHeader,
  ChallengeCard,
  ChallengeCardHeader,
  ChallengeCardTag,
  ChallengesCard,
  ChallengesCardItem,
  ChallengeCardItemTexts,
  ProgressPointsBar,
  CompleteButton,
} from './styles';
import WaterModal from '../../components/WaterModal';

const navigationTabs = [
  {
    icon: <RiDossierLine />,
    title: 'Teleconsulta',
    path: 'teleconsultation',
  },
  {
    icon: <RiCalendarLine />,
    title: 'Hoje',
    path: '',
  },
  {
    icon: <RiStore2Line />,
    title: 'Marketplace',
    path: 'marketplace',
  },
];

const navbarTabs = [
  {
    icon: <RiHomeSmile2Line />,
    title: 'Hoje',
    disabled: false,
    to: '/',
  },
  { icon: <RiOpenArmLine />, title: 'Bebê', disabled: true },
  { icon: <RiUserSmileLine />, title: 'Eu', disabled: true },
  { icon: <RiTeamLine />, title: 'Grupos', disabled: true },
];

const Home: React.FC = () => {
  const [visibleExerciseModal, setVisibleExerciseModal] =
    useState<boolean>(false);
  const [visibleGeneralExerciseModal, setVisibleGeneralExerciseModal] =
    useState<boolean>(false);
  const [visibleWaterExerciseModal, setVisibleWaterExerciseModal] =
    useState<boolean>(false);
  const [visibleSleepModal, setVisibleSleepModal] = useState<boolean>(false);
  const [visibleMeasureModal, setVisibleMeasureModal] =
    useState<boolean>(false);

  const [totalPoints, setTotalPoints] = useState<number>(0);
  const [progressPoints, setProgressPoints] = useState<number>(0);
  const [dayDate, setDayDate] = useState(0);

  const [completedChallenges, setCompletedChallenges] = useState<any>([]);

  const [challengesList, setChallengesList] = useState<Array<any>>([]);

  useEffect(() => {
    getChallengeTypes();
    getPointsInformations();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    getPointsInformations();
  }, [
    visibleExerciseModal,
    visibleGeneralExerciseModal,
    visibleWaterExerciseModal,
    visibleSleepModal,
    visibleMeasureModal,
  ]);

  function handlePointsCalculate(points: number): number {
    const pointsCalculate = (points * 100) / 1000;

    return pointsCalculate;
  }

  async function getPointsInformations(): Promise<void> {
    try {
      const { points, challenges, children } = (await customerService.getMe())?.customer;

      const waterQuantityItem = localStorage.getItem('@App:waterQuantityControl');

      if (waterQuantityItem) {
        if (new Date().getTime() > JSON.parse(waterQuantityItem)?.expiry) {
          localStorage.removeItem('@App:waterQuantityControl');
        }
      }

      if (children?.length) {
        const [onlyChildren] = children;
        getDaysBetweenDates(onlyChildren?.lastPeriod);
      }

      setCompletedChallenges(challenges || []);
      setTotalPoints(points || 0);

      const totalProgressPoints = handlePointsCalculate(points || 0);
      setProgressPoints(totalProgressPoints);
      return;
    } catch {
      console.log("Can't get points informations, try again!");
      return;
    }
  }

  function getDaysBetweenDates(date: any): any {
    const calc =
      moment(date)
        .startOf('day')
        .diff(moment(Date.now()).startOf('day'), 'days') * -1;
    setDayDate(Math.abs(calc));
  }

  function checkHasCompleted(type: string | number) {
    const filteredChallenge = completedChallenges.filter(
      (item: any) =>
        item?.challengeType?.id === type && item?.status === 'completed',
    );

    return filteredChallenge?.length > 0;
  }

  async function getChallengeTypes(): Promise<void> {
    try {
      const challengesResult = await challengesService.get();
      setChallengesList(challengesResult);
    } catch {
      console.log("Can't list challenges, try again!");
    }
  }

  interface DynamicChallengeProps {
    [key: string]: {
      image: any;
      variant: string;
      referModalMethod?: any;
    };
  };

  const dynamicChallengesContent = {
    Exercício: {
      image: ExerciseIllustrationRed,
      variant: 'secondary',
      referModalMethod: () => setVisibleGeneralExerciseModal(true) 
    },
    Alimentação: {
      image: ExerciseIllustration,
      variant: 'primary',
      referModalMethod: () => setVisibleWaterExerciseModal(true) 
    },
    Meditação: {
      image: LargeMeditationIllustration,
      variant: '',
      referModalMethod: () => setVisibleExerciseModal(true) 
    },
    Sono: {
      image: SleepIllustration,
      variant: 'tertiary',
      referModalMethod: () => setVisibleSleepModal(true)
    },
    Cuidados: {
      image: MeasureIllustration,
      variant: '',
      referModalMethod: () => setVisibleMeasureModal(true)
    },
  } as DynamicChallengeProps;

  return (
    <Container>
      <PregnantWallet />
      <SectionSwitch tabs={navigationTabs} activeIndex={1} />

      <UserHeader>
        <UserHeaderTop>
          <UserHeaderLeftContent>
            <small>{`${
              dayOfWeek[new Date()?.getDay()]
            }, ${new Date().getDate()} de ${getMonthName(new Date())}`}</small>
            <b>
              Dia {dayDate}
              <span>/1.000</span>
            </b>
          </UserHeaderLeftContent>

          <UserImage>
            <img src={Face} alt="Face" />
          </UserImage>
        </UserHeaderTop>
        <PointsControl>
          <span />
          <PointsProgress>
            <img src={Star} alt="Star" />
            <PointsTexts>
              <span>
                Meus pontos:
                <b>
                  <em>{totalPoints || 0}</em> Pts
                </b>
              </span>
              <ProgressBar>
                <ProgressPointsBar progress={progressPoints} />
              </ProgressBar>
              <small>
                Faça desafios e ganhe pontos
                <RiInformationLine />
              </small>
            </PointsTexts>
          </PointsProgress>
        </PointsControl>
      </UserHeader>

      <ChallengesCard>
        <ChallengesCardItem variant="tertiary">
          <ChallengeCardItemTexts>
            <small>Foco no</small>
            <h3>Movimento</h3>
            {checkHasCompleted(2) ? (
              <button type="button" disabled>
                Realizado!
                <RiCheckFill />
              </button>
            ) : (
              <button
                type="button"
                onClick={() => setVisibleGeneralExerciseModal(true)}
              >
                Completar agora
                <RiArrowRightSLine />
              </button>
            )}
          </ChallengeCardItemTexts>
          <img
            src={MovimentationIllustration}
            alt="MovimentationIllustration"
          />
        </ChallengesCardItem>

        <ChallengesCardItem variant="primary">
          <ChallengeCardItemTexts>
            <small>Cuidado com minha</small>
            <h3>Alimentação</h3>
            {checkHasCompleted(3) ? (
              <button type="button" disabled>
                Realizado!
                <RiCheckFill />
              </button>
            ) : (
              <button
                type="button"
                onClick={() => setVisibleWaterExerciseModal(true)}
              >
                Completar agora
                <RiArrowRightSLine />
              </button>
            )}
          </ChallengeCardItemTexts>
          <img src={FoodIllustration} alt="FoodIllustration" />
        </ChallengesCardItem>

        <ChallengesCardItem variant="secondary">
          <ChallengeCardItemTexts>
            <small>Exercícios diários</small>
            <h3>Meditação</h3>
            {checkHasCompleted(4) ? (
              <button type="button" disabled>
                Realizado!
                <RiCheckFill />
              </button>
            ) : (
              <button
                type="button"
                onClick={() => setVisibleExerciseModal(true)}
              >
                Completar agora
                <RiArrowRightSLine />
              </button>
            )}
          </ChallengeCardItemTexts>
          <img
            src={MeditationIllustration}
            alt="MeditationIllustration"
          />
        </ChallengesCardItem>

        <ChallengesCardItem variant="quartenary">
          <ChallengeCardItemTexts>
            <small>Como anda</small>
            <h3>Meu sono</h3>
            {checkHasCompleted(5) ? (
              <button type="button" disabled>
                Realizado!
                <RiCheckFill />
              </button>
            ) : (
              <button type="button" onClick={() => setVisibleSleepModal(true)}>
                Completar agora
                <RiArrowRightSLine />
              </button>
            )}
          </ChallengeCardItemTexts>
          <img src={SleepIllustration} alt="SleepIllustration" />
        </ChallengesCardItem>

        <ChallengesCardItem variant="secondary">
          <ChallengeCardItemTexts>
            <small>Registrar medidas</small>
            <h3>Cuidados</h3>
            {checkHasCompleted(6) ? (
              <button type="button" disabled>
                Realizado!
                <RiCheckFill />
              </button>
            ) : (
              <button
                type="button"
                onClick={() => setVisibleMeasureModal(true)}
              >
                Completar agora
                <RiArrowRightSLine />
              </button>
            )}
          </ChallengeCardItemTexts>
          <img
            src={MovimentationIllustration}
            alt="MovimentationIllustration"
          />
        </ChallengesCardItem>
      </ChallengesCard>

      <ChallengesSection>
        <ChallengeSectionHeader>
          <small>Desafios</small>
        </ChallengeSectionHeader>
        
        {/* List of challenges  */}
        {challengesList?.map(challenge => {
          const { ...cardInformations } = challenge?.attributes;

          return (
            <ChallengeCard
              variant={
                dynamicChallengesContent[cardInformations?.category]?.variant
              }
              completed={checkHasCompleted(challenge?.id)}
              key={challenge?.id}
            >
              <ChallengeCardHeader>
                <ChallengeCardTag>
                  {cardInformations?.category}
                </ChallengeCardTag>
              </ChallengeCardHeader>

              <img
                src={dynamicChallengesContent[cardInformations?.category]?.image}
                alt="Exercise Illustration"
              />

              <h3>{cardInformations?.name}</h3>
              <p>{cardInformations?.description}</p>

              {checkHasCompleted(challenge?.id) ? (
                <CompleteButton type="button" disabled>
                  Realizado!
                </CompleteButton>
              ) : (
                <button
                  type="button"
                  onClick={dynamicChallengesContent[cardInformations?.category]?.referModalMethod}
                >
                  Começar!
                </button>
              )}
            </ChallengeCard>
          );
        })}
      </ChallengesSection>

      <Navbar navbarTabs={navbarTabs} />

      {visibleExerciseModal && (
        <ExerciseModal
          visibleExerciseModal={visibleExerciseModal}
          toggleVisibleModal={() =>
            setVisibleExerciseModal(!visibleExerciseModal)
          }
          handleDetailModal={() => {
            setVisibleExerciseModal(false);
            setVisibleSleepModal(true);
          }}
        />
      )}

      {visibleGeneralExerciseModal && (
        <GeneralExerciseModal
          visibleExerciseModal={visibleGeneralExerciseModal}
          toggleVisibleModal={() =>
            setVisibleGeneralExerciseModal(!visibleGeneralExerciseModal)
          }
          handleDetailModal={() => {
            setVisibleGeneralExerciseModal(false);
            setVisibleExerciseModal(true);
          }}
        />
      )}

      {visibleWaterExerciseModal && (
        <WaterModal
          visibleExerciseModal={visibleWaterExerciseModal}
          toggleVisibleModal={() =>
            setVisibleWaterExerciseModal(!visibleWaterExerciseModal)
          }
          handleDetailModal={() => {
            setVisibleWaterExerciseModal(false);
            setVisibleGeneralExerciseModal(true);
          }}
        />
      )}

      {visibleSleepModal && (
        <SleepModal
          visibleExerciseModal={visibleSleepModal}
          toggleVisibleModal={() => setVisibleSleepModal(!visibleSleepModal)}
          handleDetailModal={() => {
            setVisibleSleepModal(false);
            setVisibleWaterExerciseModal(true);
          }}
        />
      )}

      {visibleMeasureModal && (
        <MeasureModal
          visibleExerciseModal={visibleMeasureModal}
          toggleVisibleModal={() =>
            setVisibleMeasureModal(!visibleMeasureModal)
          }
          handleDetailModal={() => {
            setVisibleMeasureModal(false);
            setVisibleGeneralExerciseModal(true);
          }}
        />
      )}
    </Container>
  );
};

export default Home;
