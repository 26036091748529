/* eslint-disable */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  RiStarFill,
  RiVideoAddLine,
  RiDossierLine,
  RiNotification4Line,
  RiFilter3Fill,
  RiArrowRightSLine,
  RiHeart3Line,
  RiCalendarLine,
  RiStore2Line,
  RiArrowUpDownLine,
  RiSearchLine,
  RiHomeSmile2Line,
  RiUserSmileLine,
  RiOpenArmLine,
  RiTeamLine,
  RiMenuLine,
} from 'react-icons/ri';

import Face from '../../assets/Face.svg';
import ComingSoonTeleConsultation from '../../assets/ComingSoonTeleConsultation.svg';

import PregnantWallet from '../../components/PregnantWallet';
import SectionSwitch from '../../components/SectionSwitch';
import SessionModal from '../../components/SessionModal';
import Navbar from '../../components/Navbar';

import {
  Container,
  MainHeader,
  UserInformations,
  LeftContent,
  RightContent,
  SearchInput,
  Filters,
  SpecialistList,
  SpecialistCard,
  SpecialistTexts,
  CameraButton,
  UserImage,
  SpecialistDescription,
  SpecialistTag,
  SpecialistImage,
  NestedContainer,
  ComingImage,
  MainHeaderTexts
} from './styles';

const navigationTabs = [
  {
    icon: <RiDossierLine />,
    title: 'Teleconsulta',
    path: 'teleconsultation',
  },
  {
    icon: <RiCalendarLine />,
    title: 'Hoje',
    path: '',
  },
  {
    icon: <RiStore2Line />,
    title: 'Marketplace',
    path: 'marketplace',
  },
];

const navbarTabs = [
  {
    icon: <RiHomeSmile2Line />, title: 'Hoje', disabled: false, to: '/',
  },
  { icon: <RiOpenArmLine />, title: 'Bebê', disabled: true },
  { icon: <RiUserSmileLine />, title: 'Eu', disabled: true },
  { icon: <RiTeamLine />, title: 'Grupos', disabled: true },
];

const TeleConsultation: React.FC = () => {
  const [isOpenedModal, setIsOpenedModal] = useState(false);

  return (
    <NestedContainer>
      <PregnantWallet />
      <SectionSwitch tabs={navigationTabs} activeIndex={0} />

      <Container>
        <MainHeader>
          <MainHeaderTexts>
            <h1>Teleconsulta</h1>
            <p>Encontre assistência conforme sua necessidade</p>
          </MainHeaderTexts>

          {/* <UserInformations>
            <LeftContent>
              <CameraButton>
                <RiVideoAddLine />
              </CameraButton>
              <button type="button" onClick={() => setIsOpenedModal(true)}>
                Minhas sessões
                <RiArrowRightSLine />
              </button>
            </LeftContent>
            <RightContent>
              <RiDossierLine />
              <RiNotification4Line />
              <UserImage>
                <img src={Face} alt="Face" />
              </UserImage>
            </RightContent>
          </UserInformations> */}

          {/* <SearchInput>
            <RiSearchLine />
            <input
              type="text"
              placeholder="Busque por sintoma ou profissional"
            />
          </SearchInput>
          <Filters>
            <button type="button">
              Filtros
              <RiFilter3Fill />
            </button>
            <span>
              Ordenar
              <RiArrowUpDownLine />
            </span>
          </Filters> */}
          <ComingImage>
            <img src={ComingSoonTeleConsultation} alt="ComingSoonTeleConsultation" />
          </ComingImage>
        </MainHeader>

        {/* <SpecialistList>
          <Link to={`/specialist/${1}`}>
            <SpecialistCard>
              <SpecialistImage src="https://randomuser.me/api/portraits/women/27.jpg" />
              <SpecialistTexts>
                Adriana de Carvalho
                <SpecialistDescription>
                  Pediatra Neonato
                  <span>
                    <RiStarFill />
                    5,0
                  </span>
                  <small>(2 avaliações)</small>
                </SpecialistDescription>
                <SpecialistTag>
                  <b>CRM:</b>
                  654321
                </SpecialistTag>
              </SpecialistTexts>
              <RiHeart3Line />
            </SpecialistCard>
          </Link>

          <Link to={`/specialist/${2}`}>
            <SpecialistCard>
              <SpecialistImage src="https://randomuser.me/api/portraits/men/20.jpg" />
              <SpecialistTexts>
                Jorge Gusmão Paiva
                <SpecialistDescription>
                  Pediatra
                  <span>
                    <RiStarFill />
                    4,9
                  </span>
                  <small>(2 avaliações)</small>
                </SpecialistDescription>
                <SpecialistTag>
                  <b>CRM:</b>
                  432163
                </SpecialistTag>
              </SpecialistTexts>
              <RiHeart3Line />
            </SpecialistCard>
          </Link>

          <Link to={`/specialist/${3}`}>
            <SpecialistCard>
              <SpecialistImage src="https://randomuser.me/api/portraits/women/14.jpg" />
              <SpecialistTexts>
                Carolina de Almeida
                <SpecialistDescription>
                  Gastropediatra
                  <span>
                    <RiStarFill />
                    4,2
                  </span>
                  <small>(1 avaliações)</small>
                </SpecialistDescription>
                <SpecialistTag>
                  <b>CRM:</b>
                  123456
                </SpecialistTag>
              </SpecialistTexts>
              <RiHeart3Line />
            </SpecialistCard>
          </Link>
        </SpecialistList> */}
      </Container>

      {isOpenedModal && (
        <SessionModal handleToggleModal={() => setIsOpenedModal(false)} />
      )}

      <Navbar navbarTabs={navbarTabs} />
    </NestedContainer>
  );
};

export default TeleConsultation;
