/* eslint-disable */
import React from 'react';
import {
  RiDossierLine,
  // RiNotification4Line,
  // RiFilter3Fill,
  // RiArrowRightSLine,
  RiCalendarLine,
  RiStore2Line,
  // RiArrowUpDownLine,
  // RiSearchLine,
  // RiShoppingCartLine,
  RiHomeSmile2Line,
  RiUserSmileLine,
  RiOpenArmLine,
  RiTeamLine,
  // RiMenuLine,
} from 'react-icons/ri';

import Face from '../../assets/Face.svg';
import ComingSoon from '../../assets/ComingSoon.svg';

import Navbar from '../../components/Navbar';
import PregnantWallet from '../../components/PregnantWallet';
import SectionSwitch from '../../components/SectionSwitch';

import {
  Container,
  MainHeader,
  UserInformations,
  LeftContent,
  RightContent,
  SearchInput,
  // Filters,
  CameraButton,
  UserImage,
  NestedContainer,
  ComingImage,
  MainHeaderTexts
} from './styles';

const navigationTabs = [
  {
    icon: <RiDossierLine />,
    title: 'Teleconsulta',
    path: 'teleconsultation',
  },
  {
    icon: <RiCalendarLine />,
    title: 'Hoje',
    path: '',
  },
  {
    icon: <RiStore2Line />,
    title: 'Marketplace',
    path: 'marketplace',
  },
];

const navbarTabs = [
  {
    icon: <RiHomeSmile2Line />, title: 'Hoje', disabled: false, to: '/',
  },
  { icon: <RiOpenArmLine />, title: 'Bebê', disabled: true },
  { icon: <RiUserSmileLine />, title: 'Eu', disabled: true },
  { icon: <RiTeamLine />, title: 'Grupos', disabled: true },
];

const MarketPlace: React.FC = () => (
  <NestedContainer>
    <PregnantWallet />
    <SectionSwitch tabs={navigationTabs} activeIndex={2} />

    <Container>
      <MainHeader>
        <MainHeaderTexts>
          <h1>Marketplace</h1>
          <p>Parceiros selecionados com descontos esclusivos</p>
        </MainHeaderTexts>

        {/* <UserInformations>
          <LeftContent>
            <CameraButton>
              <RiShoppingCartLine />
            </CameraButton>
            <button type="button">
              Carrinho vazio
              <RiArrowRightSLine />
            </button>
          </LeftContent>
          <RightContent>
            <RiStore2Line />
            <RiNotification4Line />
            <UserImage>
              <img src={Face} alt="Face" />
            </UserImage>
          </RightContent>
        </UserInformations> */}

        {/* <SearchInput>
          <RiSearchLine />
          <input
            type="text"
            placeholder="Busque por Carrinho de Bebê, Fralda ..."
          />
        </SearchInput> */}
        {/* <Filters>
          <button type="button">
            Filtros
            <RiFilter3Fill />
          </button>
          <span>
            Ordenar
            <RiArrowUpDownLine />
          </span>
        </Filters> */}
        
        <ComingImage>
          <img src={ComingSoon} alt="ComingSoon" />
        </ComingImage>
      </MainHeader>
    </Container>

    <Navbar navbarTabs={navbarTabs} />
  </NestedContainer>
);

export default MarketPlace;
