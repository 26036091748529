import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import {
  RiArrowLeftSLine,
  RiHeart3Line,
  RiStarFill,
  RiVidiconFill,
} from 'react-icons/ri';
import Arrow from '../../../assets/Arrow.svg';

import Button from '../../../components/Button';

import {
  Container,
  Header,
  BackButton,
  SpecialistInformations,
  SpecialistImage,
  Tag,
  HighlightsCards,
  HighlightItem,
  Icon,
  Texts,
  BackgroundIcon,
  PageSectionSwitch,
  SwitchItem,
  DescriptionSection,
  DescriptionContainer,
  SpecialtiesContainer,
  SpecialtiesList,
  SpecialtiesItem,
  CalendarContainer,
  CalendarTimes,
  DaysList,
  DaysListItem,
  TimeList,
  TimeListItem,
  CalendarSubtitles,
  UnavailableTimes,
  AvailableTimes,
  TimeListRow,
  ButtonContainer,
} from './styles';

const SpecialistDetails: React.FC = () => {
  const [viewMode, setViewMode] = useState('description');

  return (
    <Container>
      <Header>
        <Link to="/teleconsultation">
          <BackButton>
            <RiArrowLeftSLine />
          </BackButton>
        </Link>
        <RiHeart3Line />
      </Header>

      <SpecialistInformations>
        <SpecialistImage src="https://randomuser.me/api/portraits/men/20.jpg" />
        <Tag>
          <b>CRM:</b>
          123456
        </Tag>
        <h1>Adriana de Carvalho</h1>
        <small>Pediatra Neonato</small>

        <HighlightsCards>
          <HighlightItem>
            <BackgroundIcon className="rating">
              <Icon>
                <RiStarFill />
              </Icon>
            </BackgroundIcon>
            <Texts>
              <small>Avaliação</small>
              <span>
                <b>4,5</b>
                (2 avaliações)
              </span>
            </Texts>
          </HighlightItem>

          <HighlightItem>
            <BackgroundIcon className="success">
              <Icon>
                <RiVidiconFill />
              </Icon>
            </BackgroundIcon>
            <Texts>
              <small>Consultas</small>
              <span>
                <b>14</b>
                realizadas
              </span>
            </Texts>
          </HighlightItem>
        </HighlightsCards>
      </SpecialistInformations>

      <PageSectionSwitch>
        <SwitchItem
          active={viewMode === 'description'}
          onClick={() => setViewMode('description')}
        >
          Descrição
        </SwitchItem>
        <SwitchItem
          active={viewMode === 'calendar'}
          onClick={() => setViewMode('calendar')}
        >
          Agenda
        </SwitchItem>
      </PageSectionSwitch>

      {viewMode === 'description' && (
        <DescriptionSection>
          <DescriptionContainer>
            <h3>Descrição:</h3>
            <p>
              Pediatra há 10 anos, formada pela PUC-RS, com experiência em
              Neonatal, especialista em cuidados de bebês prematuros ou com
              disfunções pela instituição XPTO-RS.
            </p>
          </DescriptionContainer>

          <SpecialtiesContainer>
            <h3>Especialidades:</h3>
            <SpecialtiesList>
              <SpecialtiesItem>Pediatria</SpecialtiesItem>
              <SpecialtiesItem>Medicina Clínica</SpecialtiesItem>
              <SpecialtiesItem>Cuidados com crianças</SpecialtiesItem>
            </SpecialtiesList>
          </SpecialtiesContainer>

          <SpecialtiesContainer>
            <h3>Especialista em sintomas:</h3>
            <SpecialtiesList>
              <SpecialtiesItem>Dor de cabeça</SpecialtiesItem>
              <SpecialtiesItem>Dor nas costas</SpecialtiesItem>
              <SpecialtiesItem>Dores respiratórias</SpecialtiesItem>
            </SpecialtiesList>
          </SpecialtiesContainer>
        </DescriptionSection>
      )}

      {viewMode === 'calendar' && (
        <CalendarContainer>
          <small>
            <b>Agenda:</b>
            Selecione os horários abaixo
          </small>

          <CalendarTimes>
            <DaysList>
              <img src={Arrow} alt="Arrow Left" />

              <DaysListItem>
                Sáb
                <small>30 Jan</small>
              </DaysListItem>

              <DaysListItem>
                Dom
                <small>31 Jan</small>
              </DaysListItem>

              <DaysListItem>
                Seg
                <small>01 Fev</small>
              </DaysListItem>

              <DaysListItem>
                Ter
                <small>02 Fev</small>
              </DaysListItem>

              <img src={Arrow} alt="Arrow Right" />
            </DaysList>

            <TimeListRow>
              <TimeList>
                <TimeListItem>09:00</TimeListItem>
                <TimeListItem>10:00</TimeListItem>
                <TimeListItem>11:00</TimeListItem>
                <TimeListItem className="disabled">12:00</TimeListItem>
                <TimeListItem className="disabled">13:00</TimeListItem>
              </TimeList>

              <TimeList>
                <TimeListItem>09:00</TimeListItem>
                <TimeListItem>10:00</TimeListItem>
                <TimeListItem>11:00</TimeListItem>
                <TimeListItem className="disabled">12:00</TimeListItem>
                <TimeListItem>13:00</TimeListItem>
              </TimeList>

              <TimeList>
                <TimeListItem>09:00</TimeListItem>
                <TimeListItem className="disabled">10:00</TimeListItem>
                <TimeListItem>11:00</TimeListItem>
                <TimeListItem>12:00</TimeListItem>
                <TimeListItem>13:00</TimeListItem>
              </TimeList>

              <TimeList>
                <TimeListItem>09:00</TimeListItem>
                <TimeListItem>10:00</TimeListItem>
                <TimeListItem>11:00</TimeListItem>
                <TimeListItem>12:00</TimeListItem>
                <TimeListItem>13:00</TimeListItem>
              </TimeList>
            </TimeListRow>
          </CalendarTimes>

          <button type="button">
            Ver demais horários
          </button>

          <CalendarSubtitles>
            <AvailableTimes>
              <span />
              Horários disponíveis
            </AvailableTimes>

            <UnavailableTimes>
              <span />
              Horários indisponíveis
            </UnavailableTimes>
          </CalendarSubtitles>
        </CalendarContainer>
      )}

      <ButtonContainer>
        <Button variant="primary" message="Agendar sessão" isLoading={false} />
      </ButtonContainer>
    </Container>
  );
};

export default SpecialistDetails;
