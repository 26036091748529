/* eslint-disable */
import React, { ReactElement, useEffect, useState } from 'react';
import moment from 'moment';

import {
  RiArrowLeftSLine,
  RiTimerLine,
  RiFireLine,
  RiArrowRightSLine,
  RiExternalLinkLine,
  RiCheckFill,
  RiZzzFill,
} from 'react-icons/ri';

import DividerLogo from '../../assets/DividerLogo.svg';
import MeditationIllustration from '../../assets/MeditationIllustration.svg';
import ExerciseIllustration from '../../assets/ExerciseIllustration.svg';

import Toast from '../Toast';

import challengesService from '../../services/challenges';
import customerService from '../../services/customer';

import {
  Container,
  ExerciseHeader,
  Tag,
  ExercisePostHeader,
  Brand,
  Progress,
  MeditationCard,
  ProgressText,
  ProgressButton,
  // SocialShare,
  ExerciseContent,
  Divider,
  SectionHeader,
  OtherExerciseCard,
  OtherExerciseCardHeader,
  OtherExerciseTag,
  OtherExerciseTexts,
  ExerciseControlInputs,
  ExerciseControlInput,
} from './styles';

interface ExerciseModalProps {
  visibleExerciseModal?: boolean;
  toggleVisibleModal?: any;
  handleDetailModal?: any;
}

const SleepModal = ({
  visibleExerciseModal,
  toggleVisibleModal,
  handleDetailModal,
}: ExerciseModalProps): ReactElement => {
  const customerInformations = localStorage.getItem('@App:customerId') as any;
  const [isFinished, setIsFinished] = useState<boolean>(false);
  const [completedChallenges, setCompletedChallenges] = useState<any>([]);
  const [isVisibleToast, setIsVisibleToast] = useState<boolean>(false);
  const [sleepTime, setSleepTime] = useState<any>(null);
  const [wakeUpTime, setWakeUpTime] = useState<any>(null);
  const [toastType, setToastType] = useState<any>('success');
  const [toastMessage, setToastMessage] = useState<string>(
    'Desafio salvo com sucesso!',
  );

  useEffect(() => {
    getCompletedChallenges();
    return;
  }, []);

  async function handleChallengeFinished(): Promise<void> {
    try {
      const convertedWakeUpTime = new Date(`1970-01-01T${wakeUpTime}:00Z`).getUTCHours();
      const convertedSleepTime = new Date(`1970-01-01T${sleepTime}:00Z`).getUTCHours();

      let hourDiff = convertedWakeUpTime - convertedSleepTime;

      if (hourDiff < 0) {
        hourDiff = 24 + hourDiff;
      }

      if (hourDiff >= 8) {
        const challengeBody = {
          challengeType: 5,
          customer: JSON.parse(customerInformations),
          status: 'completed',
          sleepTime: `${sleepTime}:00.000`,
          wakeUpTime: `${wakeUpTime}:00.000`,
        };

        await challengesService.post(challengeBody);

        setToastType('success');
        setToastMessage('Desafio salvo com sucesso!');
        setIsVisibleToast(true);

        setTimeout(() => {
          setIsVisibleToast(false);
          toggleVisibleModal();
        }, 2500);
      } else {
        setToastType('error');
        setToastMessage('Você precisa selecionar mais de 8 horas por dia!');
        setIsVisibleToast(true);

        setTimeout(() => {
          setIsVisibleToast(false);
        }, 2500);
      }
    } catch {
      console.log("Can't create a challenge.");
    }
  }

  async function getCompletedChallenges(): Promise<void> {
    try {
      const { points, children, challenges } = (await customerService.getMe())
        ?.customer;

      setCompletedChallenges(challenges || []);
      return;
    } catch {
      console.log("Can't get challenge informations, try again!");
    }
  }

  function checkHasCompleted(type: string) {
    const filteredChallenge = completedChallenges.filter(
      (item: any) => item?.key === type && item?.status === 'completed',
    );

    return filteredChallenge?.length > 0;
  }

  return (
    <Container visible={visibleExerciseModal} finished={isFinished}>
      <ExerciseHeader>
        <button
          type="button"
          onClick={() => {
            toggleVisibleModal();
            setIsFinished(false);
          }}
        >
          <RiArrowLeftSLine />
        </button>
        <Tag>Exercício</Tag>
      </ExerciseHeader>

      <ExerciseContent>
        <ExercisePostHeader>
          <Brand>
            <RiZzzFill />
          </Brand>
          <h1>Dormir 8h ao dia</h1>
        </ExercisePostHeader>

        <ExerciseControlInputs>
          <ExerciseControlInput>
            <small>Fui dormir às:</small>
            <input
              type="time"
              defaultValue={sleepTime}
              onChange={e => setSleepTime(e?.target?.value)}
            />
          </ExerciseControlInput>
          <small>/</small>
          <ExerciseControlInput>
            <small>Acordei às:</small>
            <input
              type="time"
              defaultValue={wakeUpTime}
              onChange={e => setWakeUpTime(e?.target?.value)}
            />
          </ExerciseControlInput>

          <button
            type="button"
            onClick={handleChallengeFinished}
            disabled={!wakeUpTime || !sleepTime}
          >
            Salvar
          </button>
        </ExerciseControlInputs>
      </ExerciseContent>

      <SectionHeader>Outros desafios</SectionHeader>

      <OtherExerciseCard>
        <OtherExerciseCardHeader>
          <OtherExerciseTag>Alimentação</OtherExerciseTag>
        </OtherExerciseCardHeader>

        <img src={ExerciseIllustration} alt="ExerciseIllustration" />

        <OtherExerciseTexts>
          <h3>Beber ao menos 2 litros de água no dia</h3>
          <p>
            Hidratação constante é muito importante para o nosso organismo,
            ainda mais na gestação!
          </p>
          {checkHasCompleted('water') ? (
            <button type="button" disabled>
              Realizado!
              <RiCheckFill />
            </button>
          ) : (
            <button type="button" onClick={handleDetailModal}>
              Começar!
            </button>
          )}
        </OtherExerciseTexts>
      </OtherExerciseCard>

      <Toast visible={isVisibleToast} message={toastMessage} type={toastType} />
    </Container>
  );
};

export default SleepModal;
