import React, {
  createContext, useState, useEffect, useContext,
} from 'react';
import api from '../services/api';

type Props = { children: React.ReactNode };

interface UserTypes {
  identifier: string;
  name?: string;
  password?: string;
}

interface AuthContextData {
  signed: boolean;
  user: UserTypes | null;
  Login(userData: UserTypes): Promise<void>;
  AutoLogin(customerPayload: any): Promise<void>;
  Logout(): void;
}

const AuthContext = createContext({} as AuthContextData);

export const AuthProvider = ({ children }: Props): any => {
  const [user, setUser] = useState<UserTypes | null>(null);

  useEffect(() => {
    const storagedUser = localStorage.getItem('@App:user');
    const storagedToken = localStorage.getItem('@App:token');

    if (storagedToken && storagedUser) {
      setUser(JSON.parse(storagedUser));
      api.defaults.headers.common.Authorization = `Bearer ${storagedToken}`;
    }
  }, []);

  async function AutoLogin(
    customerPayload: any,
  ): Promise<void> {
    try {
      delete api.defaults.headers.common.Authorization;

      api.defaults.headers.common.Authorization = `Bearer ${customerPayload?.jwt}`;

      localStorage.setItem(
        '@App:user',
        JSON.stringify(customerPayload?.user),
      );
      localStorage.setItem('@App:token', customerPayload?.jwt);
      localStorage.setItem(
        '@App:customer',
        JSON.stringify(customerPayload?.customer),
      );
    } catch {
      throw new Error('Could not login using this credentials.');
    }
  }

  async function Login(userData: UserTypes): Promise<void> {
    try {
      delete api.defaults.headers.common.Authorization;

      const signInResponse = (
        await api.post('auth/local', {
          ...userData,
        })
      )?.data as any;

      setUser(signInResponse?.user);

      api.defaults.headers.common.Authorization = `Bearer ${signInResponse?.jwt}`;

      localStorage.setItem('@App:user', JSON.stringify(signInResponse?.user));
      localStorage.setItem('@App:token', signInResponse?.jwt);
    } catch {
      throw new Error('Could not login using this credentials.');
    }
  }

  function Logout(): void {
    setUser(null);

    localStorage.removeItem('@App:user');
    localStorage.removeItem('@App:token');
  }

  return (
    <AuthContext.Provider
      value={{
        signed: Boolean(user),
        user,
        Login,
        Logout,
        AutoLogin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const authContext = useContext(AuthContext);

  return authContext;
}

export default AuthContext;
