/* eslint-disable */
import api from './api';

export default {
  async post(childrenData: any): Promise<any> {
    try {
      return await api.post('children', {
        ...childrenData,
      });
    } catch {
      throw new Error("Can't create a children");
    }
  },

  async update(id: number, childrenData: any) {
    try {
      return await api.put(`children/${id}`, {
        data: {
          ...childrenData
        },
      })
    } catch {
      console.log("Can't update the selected children.");
      return {
        data: []
      };
    }
  },

  async attach(code: any): Promise<any> {
    try {
      return await api.post('children/attach', {
        data: {
          childCode: code
        }
      });
    } catch {
      throw new Error("Can't attach a children");
    }
  },
};
