import styled, { css } from 'styled-components';

export const NestedContainer = styled.div`
  position: relative;
  /* min-height: 100vh; */

  display: flex;
  flex-direction: column;
  align-items: center;

  > img {
    position: absolute;
    top: -140px;

    max-width: 326px;
    width: 100%;
    margin: 0 auto;
  }
`;

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: -webkit-fill-available;

    max-width: 326px;
    width: 100%;
    margin: auto;
    padding: 160px 0 0 0;

    > small {
      color: ${theme.colors.primaryDark50};
      font-size: 1.3rem;
      line-height: 2.2rem;
      font-weight: 600;
      text-align: center;
    }

    > button {
      background-color: transparent;
      color: ${theme.colors.primary};
      ${theme.texts.defaultSemiBoldMediumTitle};
      margin-top: auto;

      display: flex;
      align-items: center;

      > svg {
        margin-left: 4px;
      }
    }

    > small {
      margin-top: 20px;
    }

    > button {
      margin-top: 100px;
    }
  `};
`;

export const BrandCheck = styled.span`
  ${({ theme }) => css`
    width: 72px;
    height: 72px;
    background: rgba(81, 212, 110, 0.1);
    border-radius: 18px;
    margin-bottom: 24px;

    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
      color: ${theme.colors.secondary};
      font-size: 3.3rem;
    }
  `};
`;

export const MainTexts = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;

    > h1 {
      color: ${theme.colors.primaryDark50};
      ${theme.texts.defaultSemiBoldHeadline};
      margin-bottom: 12px;
    }

    > p {
      color: ${theme.colors.primaryDark50};
      opacity: 0.7;
      ${theme.texts.defaultRegularOption};
      text-align: center;
    }
  `};
`;

export const SupportNetworkCode = styled.div`
  ${({ theme }) => css`
    margin-top: 44px;
    width: 100%;

    display: flex;
    flex-direction: column;

    > small {
      ${theme.texts.defaultRegularBody};
      color: ${theme.colors.primaryDark50};
      margin-bottom: 14px;
    }
  `};
`;

export const CopyInput = styled.div`
  ${({ theme }) => css`
    position: relative;

    display: flex;
    align-items: center;

    > input {
      flex: 1;
      width: 100%;
      height: 50px;
      background-color: ${theme.colors.neutralLight30};
      border: 0;
      border-radius: 10px;
      padding: 0 22px;

      &:disabled {
        color: ${theme.colors.primaryDark50};
      }
    }

    > svg {
      position: absolute;
      right: 22px;
      font-size: 1.4rem;
      color: ${theme.colors.primary};
      cursor: pointer;
    }
  `};
`;
