import React, { ReactElement } from 'react';

import { Container, ToastContent } from './styles';

interface ToastProps {
  message?: string;
  visible?: boolean;
  type?: 'error' | 'success' | 'none';
}

const Toast = ({
  message = '',
  type = 'none',
  visible = false,
}: ToastProps): ReactElement => (
  <Container type={type} visible={visible}>
    <ToastContent>
      <p>
        {message}
      </p>
    </ToastContent>
  </Container>
);

export default Toast;
