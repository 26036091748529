/* eslint-disable */
import React, { ReactElement, useEffect, useState } from 'react';
import NoSleep from 'nosleep.js';

import {
  RiArrowLeftSLine,
  RiTimerLine,
  RiFireLine,
  RiArrowRightSLine,
  RiExternalLinkLine,
  RiCheckFill,
} from 'react-icons/ri';

import DividerLogo from '../../assets/DividerLogo.svg';
import MeditationIllustration from '../../assets/MeditationIllustration.svg';
import ExerciseIllustration from '../../assets/ExerciseIllustration.svg';
import SleepIllustration from '../../assets/SleepIllustration.svg';

import GeneralCountdownModal from '../GeneralCountdownModal';
import customerService from '../../services/customer';

import Toast from '../Toast';

import {
  Container,
  ExerciseHeader,
  Tag,
  ExercisePostHeader,
  Brand,
  Progress,
  MeditationCard,
  ProgressText,
  ProgressButton,
  // SocialShare,
  ExerciseContent,
  Divider,
  SectionHeader,
  OtherExerciseCard,
  OtherExerciseCardHeader,
  OtherExerciseTag,
  OtherExerciseTexts,
} from './styles';

interface ExerciseModalProps {
  visibleExerciseModal?: boolean;
  toggleVisibleModal?: any;
  handleDetailModal?: any;
}

const MeditationModal = ({
  visibleExerciseModal,
  toggleVisibleModal,
  handleDetailModal,
}: ExerciseModalProps): ReactElement => {
  const noSleep = new NoSleep();
  const [visibleCountdown, setVisibleCountdown] = useState<boolean>(false);
  const [isFinished, setIsFinished] = useState<boolean>(false);
  const [completedChallenges, setCompletedChallenges] = useState<any>([]);
  const [isVisibleToast, setIsVisibleToast] = useState<boolean>(false);

  useEffect(() => {
    getCompletedChallenges();
    setIsFinished(false);
  }, []);

  async function getCompletedChallenges(): Promise<void> {
    try {
      const { points, children, challenges } = (await customerService.getMe())
        ?.customer;

      setCompletedChallenges(challenges || []);
      return;
    } catch {
      console.log("Can't get challenge informations, try again!");
    }
  }

  function checkHasCompleted(type: string) {
    const filteredChallenge = completedChallenges.filter(
      (item: any) => item?.key === type && item?.status === 'completed',
    );

    return filteredChallenge?.length > 0;
  }

  return (
    <Container
      visible={visibleExerciseModal}
      finished={isFinished}
      noScroll={visibleCountdown}
    >
      <ExerciseHeader>
        <button
          type="button"
          onClick={() => {
            toggleVisibleModal();
            setIsFinished(false);
          }}
        >
          <RiArrowLeftSLine />
        </button>
        <Tag>Meditação</Tag>
      </ExerciseHeader>

      <ExerciseContent>
        <ExercisePostHeader>
          <Brand>
            <RiFireLine />
          </Brand>
          <h1>Fazer 5 minutos de meditação</h1>
        </ExercisePostHeader>

        <button
          type="button"
          onClick={() => {
            noSleep.enable();
            setVisibleCountdown(true);
          }}
          disabled={isFinished}
        >
          {isFinished ? (
            <>
              Desafio concluído!
              <RiCheckFill />
            </>
          ) : (
            <>
              Começar!
              <span>
                <RiTimerLine />
                05:00
              </span>
            </>
          )}
        </button>
        {/*
        <Progress>
          <small>Progresso:</small>
          <MeditationCard>
            <ProgressButton>0%</ProgressButton>

            <ProgressText>
              Tempo realizado:
              <b>0 minutos</b>
            </ProgressText>

            <img src={MeditationIllustration} alt="MeditationIllustration" />
          </MeditationCard>
        </Progress> */}

        {/* <Divider>
          <img src={DividerLogo} alt="" />
        </Divider> */}

        {/* <SocialShare>
          <h2>Gostou desse desafio?</h2>
          <p>
            Compartilhe em suas redes sociais e ajude a levar uma vida mais
            saudável para outros pais!
          </p>
        </SocialShare> */}
      </ExerciseContent>

      <SectionHeader>
        Outros desafios
        {/* <span>
          Ver tudo
          <RiArrowRightSLine />
        </span> */}
      </SectionHeader>

      <OtherExerciseCard variant="primary">
        <OtherExerciseCardHeader>
          <OtherExerciseTag>Sono</OtherExerciseTag>
          {/* <span>
            <RiExternalLinkLine />
          </span> */}
        </OtherExerciseCardHeader>

        <img src={SleepIllustration} alt="SleepIllustration" />

        <OtherExerciseTexts>
          <h3>Dormir 8h ao dia</h3>
          <p>
            Descansar é um dos pilares para o bom funcionamento dos nossos
            hormônios, registre seu sono diário.
          </p>
          {checkHasCompleted('sleep') ? (
            <button type="button" disabled>
              Realizado!
              <RiCheckFill />
            </button>
          ) : (
            <button type="button" onClick={handleDetailModal}>
              Começar!
            </button>
          )}
        </OtherExerciseTexts>
      </OtherExerciseCard>

      {visibleCountdown && (
        <GeneralCountdownModal
          handleVisibleModal={() => {
            setIsVisibleToast(true);
            noSleep.disable();
            setTimeout(() => {
              setIsVisibleToast(false);
            }, 2500);
            setVisibleCountdown(false);
          }}
          handleFinishedExercise={() => {
            setIsVisibleToast(true);
            noSleep.disable();
            setIsFinished(true);
            setTimeout(() => {
              setIsVisibleToast(false);
            }, 2500);
          }}
        />
      )}

      {isVisibleToast && (
        <Toast message="Desafio salvo com sucesso!" type="success" />
      )}
    </Container>
  );
};

export default MeditationModal;
