import styled, { css, keyframes } from 'styled-components';

import LittleWallet from '../../assets/LittleWallet.png';

interface WalletModalProps {
  isVisible?: boolean;
}

const floating = keyframes`
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translatey(3px);
  }
  100% {
    transform: translatey(0px);
  }
`;

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: 0;
    left: 0;

    padding: 5px 0 0 0;
    height: 44px;
    width: 100%;

    > img {
      width: 100%;
      cursor: pointer;

      /* animation: ${floating} 3s ease-in-out infinite; */
    }

    > button {
      ${theme.texts.defaultSemiBoldText};
      color: ${theme.colors.primaryDark50};
      cursor: pointer;
      background-color: transparent;

      display: flex;
      flex-direction: column;

      > svg {
        font-size: 1.6rem;
      }
    }
  `};
`;

export const WalletModal = styled.div<WalletModalProps>`
  ${({ theme, isVisible }) => css`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: -webkit-fill-available;
    background-color: ${theme.colors.background};
    z-index: 1000;
    transform: translateY(-150%);

    transition: 0.4s ease-in-out;

    ${isVisible
    && css`
      transform: translateY(0);
    `}
  `};
`;

export const WalletModalTexts = styled.div`
  ${({ theme }) => css`
    margin-top: 12px;
    width: 100%;
    height: 166px;
    text-align: center;
    padding-top: 58px;
    background: url(${LittleWallet}) center center no-repeat;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > h2 {
      color: ${theme.colors.primaryDark50};
      ${theme.texts.defaultSemiBoldHeadline};
      margin-bottom: 4px;
    }

    > p {
      color: ${theme.colors.primaryDark50};
      ${theme.texts.defaultRegularBody};
    }
  `};
`;

export const WalletModalContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 480px;
    width: 100%;
    margin: 0 auto;

    background-color: ${theme.colors.background};
    min-height: 100%;
    padding: 32px 32px 32px;

    > button {
      margin-top: auto;
      background-color: transparent;
      color: ${theme.colors.primaryDark50};
      ${theme.texts.defaultSemiBoldText};

      display: flex;
      flex-direction: column;

      > svg {
        font-size: 1.6rem;
      }
    }
  `};
`;

export const WalletMenu = styled.ul`
  width: 100%;
  margin-top: 28px;
`;

interface WalletMenuItemProps {
  disabled?: boolean;
  variant?: string;
}

export const WalletMenuItemIcon = styled.div`
  ${({ theme }) => css`
    background: rgba(53, 123, 248, 0.1);
    border-radius: 14px;
    height: 52px;
    width: 52px;
    margin-right: 18px;

    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
      font-size: 2.4rem;
      color: ${theme.colors.primary};
    }
  `};
`;

export const WalletMenuItemTexts = styled.div`
  ${({ theme }) => css`
    > h4 {
      ${theme.texts.defaultSemiBoldSmallTitle};
      color: ${theme.colors.primaryDark50};
      margin-bottom: 2px;
    }

    > small {
      ${theme.texts.defaultRegular};
      color: ${theme.colors.primaryDark50};
      opacity: 0.5;
    }
  `};
`;

export const WalletMenuItem = styled.li<WalletMenuItemProps>`
  ${({ disabled, variant }) => css`
    display: flex;
    align-items: center;

    cursor: pointer;

    :not(:last-child) {
      margin-bottom: 14px;
    }

    > svg {
      margin-left: auto;
      font-size: 1.6rem;
    }

    ${disabled
    && css`
      opacity: 0.3;
      cursor: not-allowed;
    `};

    ${variant === 'secondary' && css`
      ${WalletMenuItemIcon} {
        background-color: rgba(81, 212, 110, 0.1);

        > svg {
          color: #51D46E;
        }
      }
    `}

    ${variant === 'tertiary' && css`
      ${WalletMenuItemIcon} {
        background-color: rgba(252, 117, 117, 0.1);

        > svg {
          color: #FD5E5A;
        }
      }
    `}

    ${variant === 'quartenary' && css`
      ${WalletMenuItemIcon} {
        background-color: rgba(187, 178, 251, 0.1);

        > svg {
          color: #BBB2FB;
        }
      }
    `}

    ${variant === 'quinary' && css`
      ${WalletMenuItemIcon} {
        background-color: rgba(14, 197, 255, 0.1);

        > svg {
          color: #0EC5FF;
        }
      }
    `}
  `};
`;

export const MyDataModal = styled.div<WalletModalProps>`
  ${({ theme, isVisible }) => css`
    top: 0;
    bottom: 0;
    left: 0;
    position: fixed;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
    /* height: -webkit-fill-available; */
    background-color: ${theme.colors.background};
    z-index: 10000;
    transform: translateX(100%);
    padding: 24px 32px;
    /* height: 100%; */

    transition: 0.4s ease-in-out;

    ${isVisible
    && css`
      transform: translateX(0);
    `}
  `};
`;

export const MyDataForm = styled.form`
  ${({ theme }) => css`
    margin-top: 20px;

    display: flex;
    flex-direction: column;

    > button {
      margin-top: auto;
      background-color: ${theme.colors.primary};
      color: ${theme.colors.neutralLight50};
      ${theme.texts.defaultBoldButton};
      height: 50px;
      border-radius: 10px;

      transition: ${theme.transitions.default};

      &:hover {
        opacity: 0.8;
      }
    }
  `};
`;

export const InputControl = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    :not(:last-child) {
      margin-bottom: 20px;
    }

    > input {
      height: 50px;
      padding: 14px 22px;
      border: 1px solid rgba(53, 60, 72, 0.1);
      border-radius: 10px;
      background-color: transparent;

      transition: ${theme.transitions.default};

      &:focus,
      &:active {
        border-color: ${theme.colors.primaryDark50};
      }
    }

    > small {
      ${theme.texts.defaultRegularBody};
      color: ${theme.colors.primaryDark50};
      margin-bottom: 14px;
    }
  `};
`;

export const MyDataModalContent = styled.div`
  ${({ theme }) => css`
    max-width: 480px;
    margin: 0 auto;

    > span {
      ${theme.texts.defaultRegularBody};
      color: ${theme.colors.primaryDark50};
      margin-bottom: 20px;
      cursor: pointer;

      display: flex;
      align-items: center;

      > svg {
        font-size: 1.6rem;
        margin-right: 2px;
      }
    }

    > h3 {
      ${theme.texts.defaultSemiBoldHeadline};
      color: ${theme.colors.primaryDark50};
      font-weight: 600;
      margin-bottom: 4px;
      margin-top: 20px;
    }

    > small {
      color: ${theme.colors.primaryDark50};
      opacity: 0.5;
      ${theme.texts.defaultRegular};
      margin-bottom: 20px;
    }
  `};
`;

export const ChallengesHistoryModal = styled.div<WalletModalProps>`
  ${({ theme, isVisible }) => css`
    position: fixed;
    left: 0;
    top: 0;

    background-color: ${theme.colors.background};
    width: 100%;
    height: 100vh;
    z-index: 1000;
    transform: translateX(100%);

    transition: 0.4s ease-in-out;

    ${isVisible
    && css`
      transform: translateX(0);
    `}
  `};
`;

export const ChallengesHistoryModalContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;

    max-width: 480px;
    width: 100%;
    margin: 0 auto;
    padding: 32px;

    background-color: ${theme.colors.background};

    > span {
      ${theme.texts.defaultRegularBody};
      color: ${theme.colors.primaryDark50};
      margin-bottom: 20px;
      cursor: pointer;

      display: flex;
      align-items: center;

      > svg {
        font-size: 1.6rem;
        margin-right: 2px;
      }
    }

    > h3 {
      ${theme.texts.defaultSemiBoldHeadline};
      color: ${theme.colors.primaryDark50};
      margin-bottom: 4px;
    }

    > small {
      ${theme.texts.defaultRegular};
      color: ${theme.colors.primaryDark50};
      opacity: 0.5;
    }
  `};
`;

export const HistoryList = styled.div`
  ${({ theme }) => css`
    margin-top: 20px;
    max-height: 600px;
    overflow-y: scroll;
    padding: 0 0 48px 0;
    
    &::-webkit-scrollbar {
      display: none;
    }

    display: flex;
    flex-direction: column;

    > small {
      color: ${theme.colors.primaryDark50};
      font-size: 1.3rem;
      line-height: 1.8rem;
      font-weight: 400;
    }
  `};
`;

export const HistoryItem = styled.div`
  ${({ theme }) => css`
    padding-bottom: 12px;
    color: ${theme.colors.primaryDark50};
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 1.8rem;

    &:not(:last-child) {
      border-bottom: 1px solid rgba(53, 123, 248, 0.1);
      margin-bottom: 12px;
    }

    display: flex;
    align-items: center;

    > b {
      margin-left: 3px;
    }
  `};
`;

export const HistoryItemIcon = styled.div<{ variant?: any }>`
  ${({ theme, variant }) => css`
    width: 32px;
    height: 32px;
    border-radius: 6px;
    background-color: rgba(53, 123, 248, 0.1);
    margin-right: 12px;
    color: ${theme.colors.primary};
    font-size: 1.3rem;

    display: flex;
    align-items: center;
    justify-content: center;


    ${variant === 'water' && css`
      background-color: rgba(133, 240, 157, 0.1);
      color: ${theme.colors.softGreen};
    `}

    ${variant === 'exercise' && css`
      background-color:  rgba(247, 107, 141, 0.1);
      color: ${theme.colors.pink};
    `}


    ${variant === 'sleep' && css`
      background-color: rgba(166, 103, 246, 0.1);
      color: ${theme.colors.purple};
    `}
  `};
`;

export const BabyDataModal = styled.div<WalletModalProps>`
  ${({ theme, isVisible }) => css`
    top: 0;
    bottom: 0;
    left: 0;
    position: fixed;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
    /* height: -webkit-fill-available; */
    background-color: ${theme.colors.background};
    z-index: 10000;
    transform: translateX(100%);
    padding: 24px 32px;
    /* height: 100%; */

    transition: 0.4s ease-in-out;

    ${isVisible
    && css`
      transform: translateX(0);
    `}
  `};
`;

export const BabyDataForm = styled.form`
  ${({ theme }) => css`
    margin-top: 20px;

    display: flex;
    flex-direction: column;

    > small {
      ${theme.texts.defaultSemiBoldSmallTitle};
      color: ${theme.colors.primaryDark50};
      text-align: center;
      max-width: 326px;
      margin: 0 auto;
      margin-bottom: 20px;
    }

    > button {
      margin-top: auto;
      background-color: ${theme.colors.primary};
      color: ${theme.colors.neutralLight50};
      ${theme.texts.defaultBoldButton};
      height: 50px;
      border-radius: 10px;

      transition: ${theme.transitions.default};

      &:hover {
        opacity: 0.8;
      }
    }
  `};
`;
export const BabyDataModalContent = styled.div`
  ${({ theme }) => css`
    max-width: 480px;
    margin: 0 auto;

    > span {
      ${theme.texts.defaultRegularBody};
      color: ${theme.colors.primaryDark50};
      margin-bottom: 20px;
      cursor: pointer;

      display: flex;
      align-items: center;

      > svg {
        font-size: 1.6rem;
        margin-right: 2px;
      }
    }

    > h3 {
      ${theme.texts.defaultSemiBoldHeadline};
      color: ${theme.colors.primaryDark50};
      font-weight: 600;
      margin-bottom: 4px;
      margin-top: 20px;
    }

    > small {
      color: ${theme.colors.primaryDark50};
      opacity: 0.5;
      ${theme.texts.defaultRegular};
      margin-bottom: 20px;
    }
  `};
`;

export const CustomInput = styled.div`
  ${({ theme }) => css`
    position: relative;

    display: flex;
    justify-content: space-between;
    align-items: center;

    > input {
      width: 100%;
      border-radius: 10px;
      background-color: transparent;
      border: 1px solid rgba(53,60,72,0.1);
      height: 50px;
      padding: 14px 22px;
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 2.2rem; 

      &:disabled {
        background-color: ${theme.colors.neutralLight30};
      }
    }

    > svg {
      position: absolute;
      right: 22px;
      color: ${theme.colors.primary};
      font-size: 1.4rem;
      cursor: pointer;
    }
  `};  
`;
