/* eslint-disable react-hooks/exhaustive-deps */
import React, { FormEvent, useEffect, useState } from 'react';
import { RiArrowLeftSLine } from 'react-icons/ri';
import InputMask from 'react-input-mask';
import { Link } from 'react-router-dom';

import Button from '../../../components/Button';
import Input from '../../../components/Input';
import Toast from '../../../components/Toast';

import { useAuth } from '../../../contexts/AuthContext';

import {
  Container, BackButton, SignUpForm, InputControl,
} from './styles';

interface RegistrationDataInterface {
  handleNextStep?: any;
}

const RegistrationData = ({
  handleNextStep,
}: RegistrationDataInterface): any => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formInputs, setFormInputs] = useState<any>({});
  const [isVisibleToast, setIsVisibleToast] = useState<boolean>(false);
  const { Logout } = useAuth();

  useEffect(() => {
    Logout();
  }, []);

  function handleInputChange(event: any): void {
    const { name, value } = event?.target;

    setFormInputs({
      ...formInputs,
      [name]: value,
    });
  }

  function handleCustomerRegister(event: FormEvent): void {
    event.preventDefault();
    setIsLoading(true);

    setTimeout(async () => {
      try {
        sessionStorage.setItem('@App:customerServiceData', JSON.stringify(formInputs));
        handleNextStep();
        return;
      } catch {
        setIsVisibleToast(true);

        setTimeout(() => {
          setIsVisibleToast(false);
        }, 2500);
      }

      setIsLoading(false);
    }, 2000);
  }

  return (
    <Container>
      <BackButton>
        <Link to="/">
          <RiArrowLeftSLine />
          Voltar
        </Link>
      </BackButton>

      <h1>Cadastre-se</h1>

      <SignUpForm onSubmit={(e) => handleCustomerRegister(e)}>
        <InputControl>
          <small>Seu nome</small>
          <Input
            type="text"
            placeholder="Digite seu nome"
            name="name"
            required
            onChange={(e) => handleInputChange(e)}
          />
        </InputControl>

        <InputControl>
          <small>Seu CPF</small>
          <InputMask
            mask="999.999.999-99"
            placeholder="Digite seu CPF"
            className="cpfInputMask"
            required
            name="cpf"
            onChange={(e) => handleInputChange(e)}
          />
        </InputControl>

        <InputControl>
          <small>Seu e-mail</small>
          <Input
            required
            type="email"
            placeholder="Digite seu e-mail"
            name="email"
            onChange={(e) => handleInputChange(e)}
          />
        </InputControl>

        <InputControl>
          <small>Escolha uma senha</small>
          <Input
            required
            type="password"
            placeholder="****************"
            name="password"
            minLength={6}
            onChange={(e) => handleInputChange(e)}
          />
        </InputControl>

        <Button type="submit" message="Avançar" isLoading={isLoading} />
      </SignUpForm>

      {isVisibleToast && (
      <Toast
        type="error"
        message="Ocorreu um erro no servidor, tente novamente."
        visible={isVisibleToast}
      />
      )}
    </Container>
  );
};

export default RegistrationData;
