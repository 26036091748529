/* eslint-disable */
import React, { ReactElement, useEffect, useState } from 'react';

import {
  RiArrowLeftSLine,
  RiWaterFlashLine,
  RiArrowRightSLine,
  RiExternalLinkLine,
  RiCheckFill,
} from 'react-icons/ri';

import ExerciseIllustrationRed from '../../assets/ExerciseIllustrationRed.svg';
import MeasuresSmallIllustration from '../../assets/MeasuresSmallIllustration.svg';

import challengesService from '../../services/challenges';
import customerService from '../../services/customer';

import Toast from '../Toast';

import {
  Container,
  ExerciseHeader,
  Tag,
  ExercisePostHeader,
  Brand,
  ExerciseContent,
  SectionHeader,
  OtherExerciseCard,
  OtherExerciseCardHeader,
  OtherExerciseTag,
  OtherExerciseTexts,
  FormControl,
  InputControl,
  CustomInput,
  CompletedItem,
  Progress,
  ProgressButton,
  ProgressText,
  MeditationCard,
  RoundedPercentage,
} from './styles';

interface MeasureModalProps {
  visibleExerciseModal?: boolean;
  toggleVisibleModal?: any;
  handleDetailModal?: any;
}

const MeasureModal = ({
  visibleExerciseModal,
  toggleVisibleModal,
  handleDetailModal,
}: MeasureModalProps): ReactElement => {
  const customerInformations = localStorage.getItem('@App:customerId') as any;

  const [isFinished, setIsFinished] = useState<boolean>(false);
  const [completedChallenges, setCompletedChallenges] = useState<any>([]);
  const [isVisibleToast, setIsVisibleToast] = useState<boolean>(false);
  const [weight, setWeight] = useState<number | string>();
  const [systolicPressure, setSystolicPressure] = useState<number | string>();
  const [diastolicPressure, setDiastolicPressure] = useState<number | string>(
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [currentStep, setCurrentStep] = useState<number>(0);

  useEffect(() => {
    getCompletedChallenges();
  }, []);

  async function getCompletedChallenges(): Promise<void> {
    try {
      const { points, children, challenges } = (await customerService.getMe())
        ?.customer;

      setCompletedChallenges(challenges || []);
      return;
    } catch {
      console.log("Can't get challenge informations, try again!");
    }
  }

  function checkHasCompleted(type: string) {
    const filteredChallenge = completedChallenges.filter(
      (item: any) => item?.key === type && item?.status === 'completed',
    );

    return filteredChallenge?.length > 0;
  }

  async function handleMeasuresChange(e: any) {
    e?.preventDefault();
    const filledInputs = [];
    const { diastolicPressure, systolicPressure, weight } = e?.target.elements;

    if (convertedValue(diastolicPressure)) {
      filledInputs.push({ title: 'diastolicPressure' });
    }

    if (convertedValue(systolicPressure)) {
      filledInputs.push({ title: 'systolicPressure' });
    }

    if (convertedValue(weight)) {
      filledInputs.push({ title: 'weight' });
    }

    setCurrentStep(filledInputs?.length);

    const challengeBody = {
      challengeType: 6,
      customer: JSON.parse(customerInformations),
      status: filledInputs?.length >= 3 ? 'completed' : 'leave',
      systolicPressure: parseInt(convertedValue(systolicPressure)),
      diastolicPressure: parseInt(convertedValue(diastolicPressure)),
      weight: parseFloat(convertedValue(weight)),
    };

    await challengesService.post(challengeBody);

    setIsVisibleToast(true);
    setTimeout(() => {
      setIsVisibleToast(false);
      toggleVisibleModal();
    }, 2500);
  }

  function convertedValue(input: any) {
    return input?.value;
  }

  return (
    <Container visible={visibleExerciseModal} finished={isFinished}>
      <ExerciseHeader>
        <button type="button" onClick={toggleVisibleModal}>
          <RiArrowLeftSLine />
        </button>
        <Tag>Cuidados</Tag>
      </ExerciseHeader>

      <ExerciseContent>
        <ExercisePostHeader>
          <Brand>
            <RiWaterFlashLine />
          </Brand>
          <h1>Registrar medidas</h1>
        </ExercisePostHeader>

        {isFinished ? (
          <CompletedItem>
            Desafio concluído!
            <RiCheckFill />
          </CompletedItem>
        ) : (
          <>
            <small>Adicione as seguintes medidas abaixo:</small>
            <FormControl onSubmit={e => handleMeasuresChange(e)}>
              <InputControl>
                <small>Peso:</small>
                <CustomInput>
                  <input
                    type="number"
                    placeholder="0"
                    name="weight"
                    step="0.01"
                    min="0"
                    defaultValue={weight}
                    onChange={e => setWeight(e?.target?.value)}
                  />
                  <span>kg</span>
                </CustomInput>
              </InputControl>
              <InputControl>
                <small>Pressão sistólica:</small>
                <CustomInput>
                  <input
                    type="number"
                    placeholder="0"
                    name="systolicPressure"
                    min="0"
                    defaultValue={systolicPressure}
                    onChange={e => setSystolicPressure(e?.target?.value)}
                  />
                  <span>mmHg</span>
                </CustomInput>
              </InputControl>
              <InputControl>
                <small>Pressão diastólica:</small>
                <CustomInput>
                  <input
                    type="number"
                    placeholder="0"
                    name="diastolicPressure"
                    min="0"
                    defaultValue={diastolicPressure}
                    onChange={e => setDiastolicPressure(e?.target?.value)}
                  />
                  <span>mmHg</span>
                </CustomInput>
              </InputControl>
              <button
                type="submit"
                disabled={!weight && !diastolicPressure && !systolicPressure}
              >
                Registrar
              </button>
            </FormControl>

            <Progress>
              <MeditationCard>
                <RoundedPercentage>
                  <svg viewBox="0 0 36 36" className="circular-chart">
                    <path
                      className="circle-bg"
                      d="M18 2.0845
                      a 15.9155 15.9155 0 0 1 0 31.831
                      a 15.9155 15.9155 0 0 1 0 -31.831"
                    />
                    <path
                      className="circle"
                      strokeDasharray={`${((currentStep / 3) * 100).toFixed(
                        0,
                      )}, 100`}
                      d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831"
                    />
                    <text x="18" y="20.5" className="percentage">
                      {((currentStep / 3) * 100).toFixed(0)}%
                    </text>
                  </svg>
                </RoundedPercentage>

                <ProgressText>
                  Nível de conclusão:
                  <b>{currentStep}/3 Campos</b>
                </ProgressText>

                <img
                  src={MeasuresSmallIllustration}
                  alt="MeasuresSmallIllustration"
                />
              </MeditationCard>
            </Progress>
          </>
        )}
      </ExerciseContent>

      <SectionHeader>Outros desafios</SectionHeader>

      <OtherExerciseCard>
        <OtherExerciseCardHeader>
          <OtherExerciseTag>Exercício</OtherExerciseTag>
        </OtherExerciseCardHeader>

        <img src={ExerciseIllustrationRed} alt="ExerciseIllustrationRed" />

        <OtherExerciseTexts>
          <h3>Fazer 5 minutos de exercícios</h3>
          <p>
            Exercícios simples que lhe ajudarão a ficar mais relaxada com sua
            nova rotina.
          </p>
          {checkHasCompleted('exercise') ? (
            <button type="button" disabled>
              Realizado!
              <RiCheckFill />
            </button>
          ) : (
            <button type="button" onClick={handleDetailModal}>
              Começar!
            </button>
          )}
        </OtherExerciseTexts>
      </OtherExerciseCard>

      <Toast
        visible={isVisibleToast}
        message="Desafio salvo com sucesso!"
        type="success"
      />
    </Container>
  );
};

export default MeasureModal;
