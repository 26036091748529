/* eslint-disable */
import api from './api';

export default {
  async get(): Promise<any> {
    try {
      return ((await api.get('challenge-types')).data).data;
    } catch {
      console.log("Can't get challenge types.");
    }
  },

  async post(
    data?: any
  ): Promise<any> {
    try {
      return await api.post('challenges?populate=*', {
        data: {
          ...data,
        },
      });
    } catch {
      console.log("Can't create a challenge.");
    }
  },

  async update(value: number): Promise<any> {
    try {
      return await api.put(`challenges/1`, {
        data: {
          value: '',
        },
      });
    } catch {
      console.log("Can't update a challenge.");
    }
  },
};
