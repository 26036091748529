import styled, { css } from 'styled-components';

import SpecialistBG from '../../../assets/SpecialistBG.png';

export const Container = styled.div`
  padding-bottom: 12px;
`;

export const Header = styled.div`
  ${({ theme }) => css`
    height: 137px;
    padding: 0 32px;
    border-radius: 0 0 20px 20px;

    background: url(${SpecialistBG});
    background-size: cover;
    background-position: center;

    display: flex;
    align-items: center;
    justify-content: space-between;

    > svg {
      font-size: 2rem;
      color: ${theme.colors.primaryDark50};
      opacity: 0.3;
      cursor: pointer;
    }
  `};
`;

export const BackButton = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.background};
    width: 42px;
    height: 42px;
    border-radius: 14px;
    font-size: 2rem;
    color: ${theme.colors.primaryDark50};

    display: flex;
    align-items: center;
    justify-content: center;

    box-shadow: 0px 100px 80px rgba(53, 123, 248, 0.07),
      0px 12.5216px 10.0172px rgba(53, 123, 248, 0.035);

    &:active {
      > svg {
        color: ${theme.colors.primaryDark50};
      }
    }
  `};
`;

export const SpecialistInformations = styled.div`
  ${({ theme }) => css`
    margin-top: -50px;

    display: flex;
    flex-direction: column;
    align-items: center;

    > h1 {
      ${theme.texts.defaultSemiBoldLargeTitle};
      color: ${theme.colors.primaryDark50};
      margin-bottom: 6px;
    }

    > small {
      ${theme.texts.defaultRegularBody};
      color: ${theme.colors.primaryDark50};
      opacity: 0.5;
    }
  `}
`;

export const SpecialistImage = styled.img`
  ${({ theme }) => css`
    width: 98px;
    height: 98px;
    background-color: ${theme.colors.background};
    border-radius: 30px;
    border: 8px solid ${theme.colors.background};
    margin-bottom: 24px;
  `};
`;

export const Tag = styled.div`
  ${({ theme }) => css`
    background: rgba(53, 123, 248, 0.1);
    border-radius: 4px;
    color: ${theme.colors.primaryDark50};
    padding: 2px 4px;
    width: 80px;
    height: 18px;
    margin-bottom: 8px;

    display: flex;
    align-items: center;
    justify-content: center;

    > b {
      margin-right: 2px;
    }
  `};
`;

export const HighlightsCards = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: 24px 0;
`;

export const HighlightItem = styled.div`
  display: flex;
  align-items: center;

  :not(:last-child) {
    margin-right: 24px;
  }
`;

export const BackgroundIcon = styled.div`
  ${({ theme }) => css`
    width: 40px;
    height: 40px;
    margin-right: 10px;
    border-radius: 14.28px;

    display: flex;
    align-items: center;
    justify-content: center;

    &.rating {
      background-color: rgba(255, 211, 14, 0.3);

      ${Icon} {
        background-color: ${theme.icons.rating};
      }
    }

    &.success {
      background-color: rgba(81, 212, 110, 0.2);

      ${Icon} {
        background-color: ${theme.colors.secondary};
      }
    }
  `};
`;

export const Icon = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 30px;
    height: 30px;
    border-radius: 10.909px;
    color: ${theme.colors.white};
    font-size: 1.3rem;
  `}
`;

export const Texts = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.primaryDark50};

    display: flex;
    flex-direction: column;

    > small {
      ${theme.texts.defaultRegular};
      margin-bottom: 1px;
    }

    > span {
      ${theme.texts.defaultRegular};
      color: rgba(53, 60, 72, 0.5);

      display: flex;
      align-items: center;

      > b {
        ${theme.texts.defaultSemiBoldMediumTitle};
        margin-right: 3px;
        color: ${theme.colors.primaryDark50};
      }
    }
  `};
`;

export const PageSectionSwitch = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    max-width: 326px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 24px;
    padding: 6.5px 8px;
    box-shadow: 0px 100px 80px rgba(53, 123, 248, 0.07),
      0px 12.5216px 10.0172px rgba(53, 123, 248, 0.035);
    border-radius: 100px;
    background-color: ${theme.colors.neutralLight50};
    height: 49px;
  `};
`;

export const SwitchItem = styled.div<{ active?: boolean }>`
  ${({ theme, active }) => css`
    height: 36px;
    flex: 1;
    border-radius: 100px;
    color: ${theme.colors.primaryDark50};
    ${theme.texts.defaultMediumMediumTitle};
    cursor: pointer;
    transition: ${theme.transitions.default};

    display: flex;
    align-items: center;
    justify-content: center;

    ${active
    && css`
      background-color: ${theme.colors.primary};
      color: ${theme.colors.neutralLight50};
    `}
  `};
`;

export const DescriptionSection = styled.div`
  ${({ theme }) => css`

    margin-bottom: 47px;

    h3 {
      color: ${theme.colors.primaryDark50};
      margin-bottom: 8px;
      ${theme.texts.defaultBoldButton};
    }

    p {
      ${theme.texts.defaultRegular};
      color: ${theme.colors.primaryDark50};
      font-size: 1.2rem;
      line-height: 1.7rem;
    }
  `};
`;

export const DescriptionContainer = styled.div`
  margin-bottom: 24px;
  padding: 0 32px;
`;

export const SpecialtiesContainer = styled.div`
  margin-bottom: 24px;

  > h3 {
    padding: 0 32px;
  }
`;

export const SpecialtiesList = styled.ul`
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  padding: 0 32px;
 
  &::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
  }
`;

export const SpecialtiesItem = styled.li`
  ${({ theme }) => css`
    background: rgba(53, 123, 248, 0.1);
    border-radius: 100px;
    padding: 10px 14px;
    color: ${theme.colors.primary};
    ${theme.texts.defaultMediumMediumTitle};
    height: 36px;
    line-height: 16px;

    display: inline-block;

    :not(:last-child) {
      margin-right: 8px;
    }
  `};
`;

export const CalendarContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    padding: 0 32px;

    > small {
      ${theme.texts.defaultRegular};
      color: rgba(53, 60, 72, 0.5);
      font-size: 1.1rem;

      > b {
        color: ${theme.colors.primaryDark50};
        ${theme.texts.defaultBoldButton};
        margin-right: 4px;
      }
    }

    > button {
      width: 100%;
      border: 1px solid ${theme.colors.primaryLight50};
      border-radius: 10px;
      height: 50px;
      background-color: transparent;
      color: ${theme.colors.primary};
      ${theme.texts.defaultBoldButton};
      margin-top: 12px;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  `};
`;

export const CalendarTimes = styled.div`
  width: 100%;
  max-width: 326px;
  margin: 0 auto;
`;

export const DaysList = styled.div`
  display: flex;
  align-items: center;
  margin: 24px 0;

  > img {
    cursor: pointer;

    :first-child {
      transform: rotate(180deg);
      margin-right: 5.3px;
    }
  }
`;

export const DaysListItem = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.primaryDark50};
    ${theme.texts.defaultSemiBoldMediumTitle};
    text-transform: uppercase;
    max-width: 72.5px;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    :not(:last-child) {
      margin-right: 5.3px;
    }

    > small {
      text-transform: initial;
      color: ${theme.colors.primaryDark50};
      opacity: 0.5;
      ${theme.texts.defaultRegular};
      margin-top: 2px;
    }
  `}
`;

export const TimeList = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-right: 12px;
  }
`;

export const TimeListRow = styled.div`
  display: flex;
`;

export const TimeListItem = styled.div`
  ${({ theme }) => css`
    background: rgba(46, 140, 255, 0.05);
    border-radius: 4px;
    color: ${theme.colors.primary};
    width: 72.5px;

    padding: 8px 16.25px;
    font-size: 1.4rem;
    line-height: 2.2rem;
    margin-bottom: 12px;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;

    &.disabled {
      background-color: ${theme.colors.neutralLight30};
      color: ${theme.colors.primaryDark50};
      opacity: 0.5;
    }
  `};
`;

export const CalendarSubtitles = styled.div`
  ${({ theme }) => css`
    margin: 24px 0;
    color: ${theme.colors.primaryDark50};
    opacity: 0.5;
    ${theme.texts.defaultRegular};

    display: flex;
    align-items: center;

    span {
      border-radius: 4px;
      margin-right: 8px;
      width: 14px;
      height: 14px;

      display: block;
    }
  `}
`;

export const UnavailableTimes = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    > span {
      background-color: ${theme.colors.neutralLight30};
    }
  `};
`;

export const AvailableTimes = styled.div`
  margin-right: 14px;
  
  display: flex;
  align-items: center;

  > span {
    background: rgba(53, 123, 248, 0.1);
  }
`;

export const ButtonContainer = styled.div`
  padding: 0 32px 10px 32px;
`;
