import styled, { css } from 'styled-components';

export const Container = styled.div`
  margin-top: 12px;
  padding: 0 3.2rem;

  display: flex;
  flex-direction: column;

  position: relative;
`;

export const StepHeader = styled.div`
  ${({ theme }) => css`
    > span {
      opacity: 0.4;
      color: ${theme.colors.primaryDark50};
      ${theme.texts.defaultMediumText};
      font-size: 1.1rem; 
    }
  `}
`;

export const StepList = styled.div`
  display: flex;
  width: 100%;
  margin-top: 12px;
`;

export const StepItem = styled.div<{ active?: boolean }>`
  ${({ theme, active }) => css`
    height: 3px;
    flex: 1;
    border-radius: 100px;
    background-color: ${theme.colors.primary};
    opacity: 0.1;

    transition: ${theme.transitions.default};

    ${active && css`
      opacity: 1;
    `}

    &:not(:last-child) {
      margin-right: 8px;
    }
  `}
`;

export const StepFooter = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: 14px;
    
    color: ${theme.colors.primaryDark50};
    
    > small {
      ${theme.texts.defaultSemiBoldSmallTitle};
      opacity: 0.4;
      cursor: pointer;
    }
  `}
`;
