/* eslint-disable */
import React, { useState } from 'react';

import Relationship from './Relationship';
import BabyData from './BabyData';
import RegistrationData from './RegistrationData';
import SupportNetwork from './SupportNetwork';
import SupportNetworkFather from './SupportNetworkFather';

import {
  Container,
  StepHeader,
  StepList,
  StepItem,
} from './styles';

const SignUp: React.FC = () => {
  const multiStepList = [
    { number: 1, title: 'Dados Cadastrais', key: 'registration-data' },
    { number: 2, title: 'Parentesco', key: 'relationship' },
    { number: 3, title: 'Dados do bebê', key: 'baby-data' },
  ];

  const [currentStep, setCurrentStep] = useState<number>(1);
  const [relationshipBaby, setRelationShipBaby] = useState<string>('mother');

  return (
    <Container>
      {currentStep < 4 && (
        <StepHeader>
          <span>
            {`${currentStep}/${multiStepList?.length}: Dados cadastrais`}
          </span>

          <StepList>
            {multiStepList?.map((step) => (
              <StepItem
                key={step?.number}
                active={currentStep >= step?.number}
              />
            ))}
          </StepList>
        </StepHeader>
      )}

      {currentStep === 1 && (
        <RegistrationData handleNextStep={() => setCurrentStep(2)} />
      )}

      {currentStep === 2 && (
        <Relationship
          handleNextStep={() => setCurrentStep(3)}
          handlePrevStep={() => setCurrentStep(1)}
          handleLastStep={() => setCurrentStep(5)}
          handleRelationshipChange={(e: any) => setRelationShipBaby(e)}
        />
      )}

      {currentStep === 3 && (
        <BabyData
          handleNextStep={() => setCurrentStep(4)}
        />
      )}

      {currentStep === 4 && (
        <SupportNetwork />
      )}

      {currentStep === 5 && (
        <SupportNetworkFather />
      )}
    </Container>
  );
};

export default SignUp;
