import React, { ReactElement } from 'react';

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import { useWelcomed } from '../../utils/welcomed';

import {
  Container, Content, Carousel, Actions,
} from './styles';

const WelcomeScreen: React.FC = (): ReactElement => {
  const { t: translate } = useTranslation();

  const { visit } = useWelcomed();

  const items = [
    <div className="item" data-value="1">
      <img src="/assets/Carousel1.png" alt="PNG" />
      <div className="text">
        <h6>{translate('welcomeCarousel.challenge.title')}</h6>
        <p>{translate('welcomeCarousel.challenge.description')}</p>
      </div>
    </div>,
    <div className="item" data-value="2">
      <img src="/assets/Carousel2.png" alt="PNG" />
      <div className="text">
        <h6>{translate('welcomeCarousel.professionals.title')}</h6>
        <p>{translate('welcomeCarousel.professionals.description')}</p>
      </div>
    </div>,
    <div className="item" data-value="3">
      <img src="/assets/Carousel3.png" alt="PNG" />
      <div className="text">
        <h6>{translate('welcomeCarousel.network.title')}</h6>
        <p>{translate('welcomeCarousel.network.description')}</p>
      </div>
    </div>,
    <div className="item" data-value="4">
      <img src="/assets/Carousel4.png" alt="PNG" />
      <div className="text">
        <h6>{translate('welcomeCarousel.partners.title')}</h6>
        <p>{translate('welcomeCarousel.partners.description')}</p>
      </div>
    </div>,
  ];

  return (
    <Container>
      <Content>
        <img src="/assets/Logo.svg" alt="Logo" />
        <h2>{translate('welcome')}</h2>

        <Carousel>
          <AliceCarousel
            mouseTracking
            items={items}
            autoPlay
            autoPlayInterval={2000}
            infinite
            disableButtonsControls
            animationType="fadeout"
            animationDuration={800}
          />
        </Carousel>

        <Actions>
          <Button variant="secondary" message={translate('signIn')} onClick={visit} />
          <Button variant="primary" message={translate('register')} />
        </Actions>
        <p>{translate('skipStep')}</p>
      </Content>
    </Container>
  );
};

export default WelcomeScreen;
