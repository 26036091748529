import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { TRANSLATIONS_EN } from './en';
import { TRANSLATIONS_PT_BR } from './pt-br';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'ptBr',
    resources: {
      en: {
        translation: TRANSLATIONS_EN,
      },
      ptBr: {
        translation: TRANSLATIONS_PT_BR,
      },
    },
  });

i18n.changeLanguage('ptBr');
