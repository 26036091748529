/* eslint-disable */
import React, { ReactElement, useState, useEffect } from 'react';
import useStayAwake from "use-stay-awake"

import challengesService from '../../services/challenges';

import {
  Container,
  CountdownModalContent,
  ExerciseTag,
  TimerSection,
  FinishedTexts,
} from './styles';

const ExerciseCountdownModal = ({ handleVisibleModal, handleFinishedExercise }: any): ReactElement => {
  const device = useStayAwake();
  const customerInformations = localStorage.getItem('@App:customerId') as any;
  const [minutes, setMinutes] = useState<number>(5);
  const [seconds, setSeconds] = useState<number>(0);
  const [progress, setProgress] = useState<number>(0);
  const [finished, setFinished] = useState<boolean>(false);

  useEffect(() => {
    device?.preventSleeping();
    const timerInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (progress < 100) {
        setProgress(progress + 0.333333330);
        // setProgress(progress + 6.66666667);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(timerInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }

      if (minutes === 0 && seconds === 0 && !finished) {
        setFinished(true);
        handleChallengeFinished();
      }
    }, 1000);

    return () => {
      clearInterval(timerInterval);
    };
  });

  async function handleChallengeFinished(): Promise<void> {
    const challengeBody = {
      challengeType: 2,
      customer: JSON.parse(customerInformations),
      status: 'completed',
      exerciseTime: minutes,
    }

    try {
      await challengesService.post(challengeBody);
      device?.preventSleeping();
      handleFinishedExercise();
    } catch {
      // console.log("Can't create a challenge.");
    }
  }

  async function handleChallengeStop(): Promise<void> {
    const challengeBody = {
      challengeType: 2,
      customer: JSON.parse(customerInformations),
      status: 'leave',
      exerciseTime: minutes,
    }

    try {
      await challengesService.post(challengeBody);

      device?.preventSleeping();
      handleVisibleModal();
    } catch {
      // console.log("Can't create a challenge");
    }
  }

  return (
    <Container>
      <CountdownModalContent>
        <ExerciseTag>Exercício</ExerciseTag>
        <h1>Fazer 5 minutos de exercício</h1>

        <TimerSection>
          <svg viewBox="0 0 36 36" className="circular-chart">
            <path
              className={`circle-bg ${finished ? 'finished' : ''}`}
              d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
            />
            <path
              className={`circle ${finished ? 'finished' : ''}`}
              strokeDasharray={`${progress}, 100`}
              d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
            />
            {finished ? (
              <text x="50%" y="50%" className="percentage-finished">
                Feito!
              </text>
            ) : (
              <>
                <text x="18" y="19" className="percentage">
                  {minutes}
                  :
                  {seconds < 10 ? `0${seconds}` : seconds}
                </text>
                <text x="18" y="22.70" className="percentage_small">
                  Restante
                </text>
              </>
            )}
          </svg>
        </TimerSection>

        {!finished ? (
          <>
            <button type="button" onClick={() => handleChallengeStop()}>
              Parar desafio
            </button>
          </>
        ) : (
          <FinishedTexts>
            <small>Bom trabalho!</small>
            <button type="button" onClick={handleVisibleModal}>
              Voltar
            </button>
          </FinishedTexts>
        )}

      </CountdownModalContent>
    </Container>
  );
};

export default ExerciseCountdownModal;
