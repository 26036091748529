import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
`;

export const ModalContainer = styled.div`
  ${({ theme }) => css`
    max-width: 480px;
    width: 100%;
    height: 100%;
    background-color: ${theme.colors.background};
    margin: 0 auto;
    padding: 16px 32px;
    border-radius: 0 0 6px 6px;
  `}
`;

export const ModalHeader = styled.div``;

export const HeaderNavigation = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    > button {
      background-color: ${theme.colors.neutralLight50};
      border-radius: 14px;
      width: 42px;
      height: 42px;
      box-shadow: 0px 100px 80px rgba(53, 123, 248, 0.07),
        0px 12.5216px 10.0172px rgba(53, 123, 248, 0.035);

      > svg {
        font-size: 20px;
      }
    }

    > h1 {
      color: ${theme.colors.primaryDark50};
      ${theme.texts.defaultSemiBoldLargeTitle};
      margin-left: 20px;
    }
  `};
`;

export const ModalFilters = styled.div``;

export const SearchInput = styled.div`
  ${({ theme }) => css`
    position: relative;
    margin: 20px 0;

    display: flex;
    align-items: center;

    > svg {
      color: ${theme.colors.primary};
      font-size: 12px;

      position: absolute;
      left: 16px;
    }
    
    > input {
      border: 0;
      border-radius: 16px;
      background-color: ${theme.colors.neutralLight50};
      width: 100%;
      height: 49px;
      padding: 16px 16px 16px 40px;
      ${theme.texts.defaultRegularBody};
      color: ${theme.colors.primaryDark50};

      &::placeholder {
        opacity: 0.2;
      }
    }
  `};
`;

export const FiltersRow = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;

    color: ${theme.colors.primaryDark50};
    ${theme.texts.defaultRegular};
  `};
`;

export const FiltersButton = styled.div`
  display: flex;
  align-items: center;

  > svg {
    margin-left: 4px;
  }
`;

export const OrderButton = styled.div`
  display: flex;
  align-items: center;

  > svg {
    margin-left: 4px;
  }
`;

export const ModalContent = styled.div`
  ${({ theme }) => css`
    > h3 {
      margin: 20px 0;
      color: ${theme.colors.primaryDark50};
      font-weight: 600;
      letter-spacing: -0.1px;
      ${theme.texts.defaultSemiboldSmallTitle};
    }
  `};
`;

export const SessionList = styled.div``;

export const SessionCard = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.neutralLight50};
    border-radius: 6px;
    padding: 20px;

    > button {
      border-radius: 10px;
      background-color: transparent;
      border: 1px solid ${theme.colors.primary};
      width: 100%;
      height: 50px;
      ${theme.texts.defaultBoldButton};
      color: ${theme.colors.primary};
    }
  `};
`;

export const SessionCardHeader = styled.div`
  ${({ theme }) => css`
    margin-bottom: 14px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    > span {
      color: ${theme.colors.primaryDark50};
      opacity: 0.5;
      ${theme.texts.defaultRegular};
    }

    > button {
      border-radius: 6px;
      background: rgba(253, 94, 90, 0.1);
      color: #FD5E5A;
      height: 24px;
      width: 24px;

      display: flex;
      align-items: center;
      justify-content: center;

      > svg {
        font-size: 10px;
      } 
    }
  `};
`;

export const ScheduledTag = styled.div`
  ${({ theme }) => css`
    width: 100%;
    background: rgba(53, 123, 248, 0.1);
    border-radius: 8px;
    height: 46px;
    padding: 12px 16px;
    margin: 14px 0 12px 0;
    ${theme.texts.defaultMediumText};
    color: ${theme.colors.primaryDark50};
    cursor: pointer;

    display: flex;
    align-items: center;

    > b {
      color: ${theme.colors.primary};
      ${theme.texts.defaultBoldButton};
      margin-left: 4px;
    }

    > svg {
      color: ${theme.colors.primary};
      font-size: 16px;
      margin-right: 10px;
    }
  `};
`;

export const SessionCardProfessional = styled.div`
  display: flex;
  align-items: center;
`;

export const ProfissionalImage = styled.img`
  ${({ theme }) => css`
    width: 60px;
    height: 60px;
    background-color: ${theme.colors.background};
    border-radius: 14px;
    margin-right: 16px;
  `};
`;

export const ProfissionalTexts = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    > h4 {
      color: ${theme.colors.primaryDark50};
      font-size: 1.3rem;
      line-height: 22px;
    }

    > small {
      color: ${theme.colors.primary};
      ${theme.texts.defaultSemiboldText};
      margin-bottom: 4.5px;
      font-weight: 500;
    }

    > span {
      display: flex;
      align-items: center;

      color: ${theme.icons.rating};
      ${theme.texts.defaultSemiboldText};
      font-weight: 600;
      margin-bottom: 4.5px;

      > svg {
        margin-right: 2px;
      }

      > b {
        ${theme.texts.defaultRegular};
        font-weight: normal;
        margin-left: 4px;
        color: ${theme.colors.primaryDark50};
        opacity: 0.5;
      }
    }
  `};
`;

export const SessionStatus = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin: 14px 0 12px 0;
    ${theme.texts.defaultRegular};
    color: ${theme.colors.primaryDark50};
  `};
`;

export const StatusTag = styled.div`
  ${({ theme }) => css`
    ${theme.texts.defaultSemiBoldText};
    text-transform: uppercase;
    background-color: ${theme.colors.secondary};
    color: ${theme.colors.neutralLight50};
    padding: 4px 8px;
    border-radius: 4px;

    display: flex;
    justify-content: center;
    align-items: center;
  `};
`;

export const InformationRow = styled.div`
  ${({ theme }) => css`
    color: rgba(53, 60, 72, .5);
    ${theme.texts.defaultRegular};
    line-height: 22px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    > b {
      font-weight: normal;
      color: ${theme.colors.primaryDark50};
    }
  `};
`;
